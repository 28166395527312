import React, { memo } from 'react';
import classNames from 'classnames';
import Picture from '../../../../../../../../../common/components/Picture';
import LegalAdviceSearch from '../../../../../LegalAdviceSearch';
import { isInsideColumn } from '../../../../../../../../shared/helpers/isInsideColumn';
import { STYLE_SCALEW_700 } from '../../../../../../../../../shared/constants/images';
import grid from '../../../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';

const MinistageSearch = ({ ministageParagraph, origin }) => {
  const { image, headline, lead, subhead } = ministageParagraph;
  const isInColumn = isInsideColumn(origin);

  return (
    <div
      data-testid="ministage-search"
      className={classNames(styles.Wrapper, {
        [grid.ContainerInner]: isInColumn,
        [classNames(grid.Container, styles.WrapperLarge)]: !isInColumn,
      })}
    >
      {image?.relativeOriginPath && (
        <div className={styles.ImageWrapper}>
          <Picture
            relativeOrigin={image.relativeOriginPath}
            style_320={STYLE_SCALEW_700}
            alt={headline || ''}
            disableWrapperClassName
            focalPointX={image.focalPointX}
            focalPointY={image.focalPointY}
          />
        </div>
      )}
      <div className={styles.ContentWrapper}>
        <div className={styles.Badge}>Rechtsratgeber</div>
        {headline && <div className={styles.HeaderText}>{headline}</div>}
        {lead && <div className={styles.LeadText}>{lead}</div>}
        <LegalAdviceSearch
          placeholder={subhead}
          addClass={styles.Search}
          hasSuggestions={false}
          isLabelHidden={true}
        />
      </div>
    </div>
  );
};

export default memo(MinistageSearch);

import React from 'react';
import compose from 'recompose/compose';
import shouldUpdate from 'recompose/shouldUpdate';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Tooltip from '../../../../../../components/Tooltip';
import PaidArticleIcon from './../PaidArticleIcon';
import locationStateSelector from '../../../../../../../../../shared/selectors/locationStateSelector';
import {
  ADVERTISING_TYPE_ADVERTORIAL,
  ADVERTISING_TYPE_ADVERTORIAL_LABEL,
  ADVERTISING_TYPE_LONGFORM,
  ADVERTISING_TYPE_NATIVE_ARTICLE,
  ARTICLE_TYPE_OPINION,
  ARTICLE_TYPE_OPINION_LABEL,
  ANCHOR_TITLE,
  ANCHOR_SHORT_TITLE,
} from '../../../../../../../../../shared/constants/content';
import styles from './styles.legacy.css';
import type {
  ArticleLeadRatgeberProps,
  ArticleLeadShortTitleProps,
} from './typings';

type ArticleLeadRatgeberPropsInner = ArticleLeadRatgeberProps & {
  isHybridApp: boolean;
};

const linkData = {
  path: '/werbung-und-inhalte',
  text: 'Mehr erfahren ...',
};

const ArticleLeadShortTitle = ({
  shortTitle,
  isAdvertorial,
  restrictionStatus,
}: ArticleLeadShortTitleProps) => (
  <span
    id={ANCHOR_SHORT_TITLE}
    data-testid="article-lead-ratgeber-short-title-wrapper"
    className={classNames({
      [styles.ShortTitle]: !isAdvertorial,
      [styles.SponsoredShortTitle]: isAdvertorial,
    })}
  >
    <PaidArticleIcon restrictionStatus={restrictionStatus} />
    {shortTitle}
  </span>
);

const ArticleLeadRatgeber = ({
  article,
  layout,
  articleColStyle,
  isHybridApp,
}: ArticleLeadRatgeberPropsInner) => {
  const isAdvertorial =
    article.subtypeValue === ADVERTISING_TYPE_ADVERTORIAL ||
    article?.subtypeValue === ADVERTISING_TYPE_LONGFORM ||
    article.subtypeValue === ADVERTISING_TYPE_NATIVE_ARTICLE;
  const isOpinion = article.subtypeValue === ARTICLE_TYPE_OPINION;

  const shortTitle: string =
    article.shortTitle ||
    (isAdvertorial && ADVERTISING_TYPE_ADVERTORIAL_LABEL) ||
    (isOpinion && ARTICLE_TYPE_OPINION_LABEL) ||
    article.channel?.title ||
    '';

  return (
    <div
      className={classNames('article-lead-ratgeber', styles.Wrapper, {
        [styles.NoMarginTop]: isHybridApp,
      })}
      data-testid="article-lead-ratgeber-wrapper"
    >
      <div className={articleColStyle}>
        {!layout && (
          <>
            {shortTitle &&
              ((isAdvertorial && (
                <Tooltip
                  content="Dieser Inhalt wurde von oder in Zusammenarbeit mit einem Werbepartner erstellt."
                  link={linkData}
                  origin={ADVERTISING_TYPE_ADVERTORIAL}
                >
                  <ArticleLeadShortTitle
                    isAdvertorial={isAdvertorial}
                    shortTitle={shortTitle}
                    restrictionStatus={article?.restrictionStatus}
                  />
                </Tooltip>
              )) || (
                <ArticleLeadShortTitle
                  isAdvertorial={isAdvertorial}
                  shortTitle={shortTitle}
                  restrictionStatus={article?.restrictionStatus}
                />
              ))}
            <h1 id={ANCHOR_TITLE} className={styles.Title}>
              <span
                className={classNames({
                  [styles.SponsoredMainTitle]: isAdvertorial,
                  [styles.MainTitle]: !isAdvertorial,
                })}
                itemProp="headline"
              >
                {article.title}
              </span>
            </h1>
          </>
        )}
        <p className={styles.Lead}>{article.lead}</p>
      </div>
    </div>
  );
};

const updatePolicy = shouldUpdate(
  (props: ArticleLeadRatgeberProps, nextProps: ArticleLeadRatgeberProps) =>
    props.article !== nextProps.article,
);

const mapStateToProps = (state) => ({
  isHybridApp: locationStateSelector(state).isHybridApp,
});

export default compose(
  connect(mapStateToProps),
  updatePolicy,
)(ArticleLeadRatgeber);

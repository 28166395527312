import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import authStateSelector from '../../../../../../shared/selectors/authStateSelector';
import { checkKMUAccess } from '../helpers/checkKMUAccess';

export const useKmu = (location) => {
  const { q: searchQuery = '', types: types = '', kmu = '' } = location.query;
  const legalAdviceSubscriptions = useSelector(
    (state) => authStateSelector(state).legalAdviceSubscriptions,
  );
  const initialAuthRequest = useSelector(
    (state) => authStateSelector(state).initialAuthRequest,
  );
  const hasKMUAccess = checkKMUAccess(legalAdviceSubscriptions);
  const waitForAuth = (!initialAuthRequest || !hasKMUAccess) && !!kmu;

  const navigate = useNavigate();
  let newKmu = kmu;
  if (
    initialAuthRequest &&
    ((!hasKMUAccess && !!kmu) || (hasKMUAccess && !kmu))
  ) {
    newKmu = hasKMUAccess ? '1' : '';
  }

  const queryParams = new URLSearchParams({
    ...(searchQuery && { q: searchQuery }),
    ...(types && { types: types }),
    ...(newKmu && { kmu: newKmu }),
  }).toString();

  useEffect(() => {
    if (newKmu !== kmu) {
      navigate(global.location.pathname + '?' + queryParams, {
        replace: true,
      });
    }
  }, [newKmu, kmu, navigate, queryParams]);

  return {
    waitForAuth,
    hasKMUAccess: !!newKmu,
    queryParams,
  };
};

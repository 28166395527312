import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import classNames from 'classnames';
import lodashSlice from 'lodash/slice';
import { enrichArticleBodyWithADs } from '../../../../../../../shared/helpers/ads';
import { getServiceUrl } from '../../../../../../../shared/helpers/serviceUrl';
import {
  extractParents,
  mapBreadcrumbsData,
} from '../../../../../../shared/helpers/customBreadcrumbs';
import headerStateSelector from '../../../../../../../shared/selectors/headerStateSelector';
import windowStateSelector from '../../../../../../../shared/selectors/windowStateSelector';
import withScrollDownToAnchor from '../../../../../../../shared/decorators/withScrollDownToAnchor';
import TestFragment from '../../../../../../../shared/tests/components/TestFragment';
import AppNexus from '../../../../components/AppNexus';
import ArticleAttachment from '../../../../components/ArticleAttachment';
import EditButtons from '../../../../components/EditButtons';
import LegalAdviceSearch from '../../../../components/LegalAdviceSearch';
import Paragraphs from '../../../../components/Paragraphs';
import ProgressBar from '../../../../components/ProgressBar';
import Tools from '../../../../components/Tools';
import ArticleHead from '../../../Article/components/ArticleHead';
import ArticleRecommendations from '../../../Article/components/ArticleRecommendations';
import RelatedBook from './components/RelatedBook';
import { RIGHT_COLUMN_PAGE_LAYOUT_TYPE } from '../../../../../../../common/screens/PageTemplate/constants';
import { MMR_1 } from '../../../../../../../shared/constants/adZone';
import {
  PIANO_CONTAINER_INLINED,
  PIANO_PLACEHOLDER_INLINED,
} from '../../../../../../../shared/constants/piano';
import {
  TRACKING_CLASS_ARTICLE_BODY,
  TRACKING_CLASS_ARTICLE_HEADER,
} from '../../../../../../../shared/constants/tracking';
import {
  ROUTE_LEGAL_ADVICE,
  ROUTE_LEGAL_ADVICE_PARENT,
} from '../../../../constants';
import { PARAGRAPHS_FOR_FREE_LEGAL_ADVICE } from '../../../Article/constants';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import { ArticlePageLegalAdviceProps } from './typings';

export const getFallbackTitle = () => 'Artikel';

export const legalAdviceBreadcrumbsData = (data) => {
  const legalAdviceArticle = data?.environment?.routeByPath.object;
  const legalAdviceChannel =
    legalAdviceArticle?.channels?.[0] || legalAdviceArticle?.channel || null;

  if (legalAdviceChannel) {
    data.breadcrumbsData = {
      activeMenuTrail: {
        edges: [
          mapBreadcrumbsData({
            title: 'Beratung',
            preferredUri: `/${ROUTE_LEGAL_ADVICE_PARENT}`,
            id: '0',
          }),
          mapBreadcrumbsData({
            title: 'Rechtsratgeber',
            preferredUri: `/${ROUTE_LEGAL_ADVICE}`,
            id: '1',
          }),
          // extractParents is reversed because configuration starts from article node,
          // not from the beginning of the route
          ...extractParents({
            channel: legalAdviceChannel,
            key: 'parent',
            prefixUrl: ROUTE_LEGAL_ADVICE,
          }).reverse(),
          mapBreadcrumbsData(legalAdviceChannel, ROUTE_LEGAL_ADVICE),
        ],
      },
      title: legalAdviceArticle?.title || '',
    };
  }
};

function getAttachmentSource(article) {
  const attachment = article.attachment;
  if (attachment) {
    return (
      `${getServiceUrl(__ATTACHMENTS_ENDPOINT__)}/get?path=${article.nid}/` +
      encodeURIComponent(attachment.systemFilename) +
      '&filename=' +
      encodeURIComponent(attachment.filename)
    );
  }
  return null;
}

const ArticlePageLegalAdvice = ({
  article,
  shouldHideContent,
  isCrawler,
  pageLayoutType,
}: ArticlePageLegalAdviceProps) => {
  const isSplittedPageLayout = [RIGHT_COLUMN_PAGE_LAYOUT_TYPE].includes(
    pageLayoutType,
  );

  const body =
    (shouldHideContent &&
      !isCrawler &&
      lodashSlice(article.body, 0, PARAGRAPHS_FOR_FREE_LEGAL_ADVICE)) ||
    article.body;

  const enrichedArticleBody = enrichArticleBodyWithADs({ pageBody: body });
  const origin = article.subtypeValue;
  const bodyId = `article-body-${article.nid}`;
  const attachment = article.attachment
    ? {
        ...article.attachment,
        source: getAttachmentSource(article),
      }
    : null;

  const getRelatedBook = () => {
    if (article?.relatedBook) {
      return article.relatedBook;
    }

    const relatedBooksInChannels =
      article?.channels?.filter((channel) => !!channel?.relatedBook) || [];

    if (
      relatedBooksInChannels.length &&
      relatedBooksInChannels?.[0]?.relatedBook
    ) {
      return relatedBooksInChannels[0].relatedBook;
    }

    return article.channel?.relatedBook;
  };

  const relatedBook = getRelatedBook();

  return (
    <>
      <div
        className={classNames(
          'article-detail',
          'article-detail-legal-advice',
          styles.Wrapper,
        )}
        data-testid="articlepage-wrapper"
        id={bodyId}
      >
        <ProgressBar trackingElementId={bodyId} />

        <EditButtons
          editContentUri={article.editContentUri}
          editRelationUri={article.editRelationUri}
          cloneContentUri={article.cloneContentUri}
          origin={origin}
        />
        {!article?.channel?.suppressAds && (
          <div className={'ad-wrapper ad-wrapper-top'}>
            <div className={grid.Container}>
              <AppNexus slot={MMR_1} deviceType="mobile" />
            </div>
          </div>
        )}

        <div
          className={classNames(
            TRACKING_CLASS_ARTICLE_HEADER,
            styles.ArticleHeader,
          )}
        >
          <div className={classNames(styles.HiddenForDesktop)}>
            <LegalAdviceSearch preserveScrollProgress={true} />
          </div>

          <ArticleHead
            article={article}
            articleColStyle={grid.ColSm24}
            component={article.subtypeValue}
            withComments={false}
            pageLayoutType={pageLayoutType}
          />
        </div>

        <div
          className={classNames(
            TRACKING_CLASS_ARTICLE_BODY,
            styles.BodyWrapper,
          )}
        >
          <TestFragment data-testid="articlepage-paragraphs">
            <Paragraphs
              pageBody={enrichedArticleBody}
              contentGcid={article.gcid || null}
              articleKeywords={article.keywords}
              colStyle={grid.ColSm24}
              origin={origin}
              paragraphsForFree={
                (shouldHideContent && PARAGRAPHS_FOR_FREE_LEGAL_ADVICE) || null
              }
              isAdSuppressed={article?.channel?.suppressAds}
              pageLayoutType={pageLayoutType}
              addClass={classNames({
                [styles.HiddenContent]: shouldHideContent,
              })}
            />
          </TestFragment>

          {shouldHideContent && !isCrawler && (
            <div
              className={classNames(styles.Paywall, {
                [styles.HiddenPaywall]: !shouldHideContent,
              })}
            >
              <div
                className={classNames({
                  [grid.Container]: !isSplittedPageLayout,
                })}
              >
                <div className={grid.Row}>
                  <div className={grid.Col24}>
                    <div
                      id={PIANO_CONTAINER_INLINED}
                      className={PIANO_PLACEHOLDER_INLINED}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {(!shouldHideContent || isCrawler) && (
            <>
              {attachment && (
                <ArticleAttachment
                  attachmentBoxTitle={'Anhang'}
                  attachment={attachment}
                />
              )}

              {article.recommendations?.count > 0 && (
                <Tools data={article.recommendations} />
              )}

              {relatedBook && (
                <div className={styles.BookTeaserWrapper}>
                  <RelatedBook bookTeaser={relatedBook} hasNext={false} />
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {article.canonicalUri && (
        <ArticleRecommendations
          article={article}
          pageLayoutType={pageLayoutType}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: Record<string, any>): Record<string, any> => ({
  viewportLabel: windowStateSelector(state).viewport.label,
  noHeader: headerStateSelector(state).noHeader,
});

export default compose(
  connect(mapStateToProps),
  withScrollDownToAnchor,
)(ArticlePageLegalAdvice);

/* istanbul ignore file */

import classNames from 'classnames';
import searchFormFactory from '../../../../../common/components/SearchForm/factory';
import Icon from './../Icon';
import grid from '../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';

const SearchForm = searchFormFactory({
  Autocomplete: () => null,
  appSearchRoute: 'suche',
  Icon,
  IconTypes: {
    closeIconType: 'IconXMark',
    submitIconType: 'IconArrowRight',
    inputIconType: 'IconMagnifyingGlass',
  },
  styles: {
    Wrapper: styles.Wrapper,
    InputIcon: classNames(grid.HiddenSmDown, styles.InputIcon),
    InputWrapper: styles.InputWrapper,
    Input: styles.Input,
    InputStylesInputFocused: styles.InputStylesInputFocused,
    InputWithIconWrapper: styles.InputWithIconWrapper,
    SubmitButton: classNames(grid.HiddenSmDown, styles.SubmitButton),
    ButtonStylesInputHasFocus: styles.ButtonStylesInputHasFocus,
    SubmitButtonActive: '',
    SubmitIcon: styles.SubmitIcon,
    ResetIcon: styles.ResetIcon,
  },
  isButtonShown: false,
  appPlaceholderMessage: 'Suchen',
  isBrowserAutoCompleteEnabled: false,
});

export default SearchForm;

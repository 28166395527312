import React, { memo } from 'react';
import compose from 'recompose/compose';
import withHelmet from '../../../../shared/decorators/withHelmet';
import PageScreenBooking from './components/PageScreenBooking';
import PageScreenDefault from './components/PageScreenDefault';
import PageScreenMarketing from './components/PageScreenMarketing';
import {
  ADVERTISING_TYPE_LONGFORM,
  PAGE_TYPE_MARKETING,
  PAGE_TYPE_MARKETING_DEFAULT_HEADER,
} from '../../../../../shared/constants/content';
import {
  BOOKING_FORM_PARAGRAPH,
  INPUT_FORM_PARAGRAPH,
} from '../../../../../shared/constants/paragraphs';
import { ROOT_SCHEMA_TYPE_WEB_PAGE } from '../../../../../shared/constants/structuredData';
import { PageScreenProps } from './typings';

type PageScreenPropsInner = PageScreenProps & {
  setVertical: (vertical: string) => Record<string, any>;
  routePathname: string;
};

export function pageBreadcrumbsData(data: any): any {
  data.breadcrumbsData = {
    label: data?.environment?.routeByPath?.object?.title || '',
  };
}

const PageScreen = (props: PageScreenPropsInner) => {
  const { subtypeValue } = props.pageScreen;

  if (
    [
      PAGE_TYPE_MARKETING,
      PAGE_TYPE_MARKETING_DEFAULT_HEADER,
      ADVERTISING_TYPE_LONGFORM,
    ].includes(subtypeValue)
  ) {
    return <PageScreenMarketing {...props} />;
  }

  const bookingFormEntry = props.pageScreen?.body?.find((entry: any) => {
    return (
      entry?.__typename === INPUT_FORM_PARAGRAPH &&
      entry.form === BOOKING_FORM_PARAGRAPH
    );
  });

  if (bookingFormEntry) {
    return <PageScreenBooking {...props} bookingFormEntry={bookingFormEntry} />;
  }

  return <PageScreenDefault {...props} />;
};

export default compose(
  withHelmet({
    getNode: ({ pageScreen }: PageScreenPropsInner) => pageScreen,
    rootSchemaType: ROOT_SCHEMA_TYPE_WEB_PAGE,
  }),
)(memo(PageScreen));

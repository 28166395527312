/* istanbul ignore file */

import { RaschApolloConfig } from '../../../../../shared/decorators/withRaschRouterFactory';
import {
  DEFAULT_PUBLICATION,
  ROUTE_HOME,
  ROUTE_LEGAL_ADVICE,
} from '../../constants';
import { KEYWORD_PAGE_SIZE } from '../../screens/Keywords/screens/KeywordArticlesList/constants';
import {
  LANDING_PAGE_GRID_PAGE_SIZE,
  LANDING_PAGE_LEGAL_ADVICE_PAGE_SIZE,
} from '../../screens/LandingPage/constants';
import { PUBLICATION_GROUP_RECHTSRATGEBER } from '../../../../../shared/constants/publications';
import { ROUTER_ROUTE_BY_PATH_QUERY } from './queries';
import { GET_LEGAL_ADVICE_DATA } from '../../screens/LegalAdvice/LandingPage/queries';

export const apolloConfig: RaschApolloConfig = {
  options: ({ location }) => {
    // encoding the pathname is no longer needed, since location.pathname already returns a encoded string
    const pathname = location.pathname.substr(1);
    const page = location.query?.page || 1;
    let additionalQueries = {};

    if (
      pathname.startsWith(ROUTE_LEGAL_ADVICE) &&
      pathname.split('/').length <= 5
    ) {
      const {
        q: searchQuery = '',
        types: types = '',
        kmu = '',
      } = location.query;
      const hasKMUAccess = !!kmu;
      additionalQueries = {
        additionalQuery: GET_LEGAL_ADVICE_DATA,
        additionalVariables: {
          path: location.pathname,
          query: searchQuery,
          limit: LANDING_PAGE_LEGAL_ADVICE_PAGE_SIZE,
          offset: LANDING_PAGE_LEGAL_ADVICE_PAGE_SIZE * (page - 1),
          types: types,
          hasKMUAccess,
        },
      };
    }

    return {
      query: ROUTER_ROUTE_BY_PATH_QUERY,
      variables: {
        path: pathname ? pathname : ROUTE_HOME,
        publication: DEFAULT_PUBLICATION,
        additionalPublications: [PUBLICATION_GROUP_RECHTSRATGEBER],
        landingPageGridPageSize: LANDING_PAGE_GRID_PAGE_SIZE,
        landingPageGridOffset: (page - 1) * LANDING_PAGE_GRID_PAGE_SIZE,
        keywordsPageSize: KEYWORD_PAGE_SIZE,
        keywordsOffset: (page - 1) * KEYWORD_PAGE_SIZE,
      },
      ...additionalQueries,
    };
  },
};

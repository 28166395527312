import React from 'react';
import classNames from 'classnames';
import { VideoType } from '../../../../../../../../../shared/helpers/createVideoObjectJsonLd';
import { ensureVideoItem } from '../../../../../../../../../shared/helpers/ensureVideo';
import AuthorDateBlock from '../../../../../../components/AuthorDateBlock';
import VideoParagraph from '../../../../../../components/Paragraphs/components/VideoParagraph';
import ImageCaption from '../../../../../../components/Paragraphs/components/ImageCaption';
import { ARTICLE_TYPE_RATGEBER } from '../../../../../../../../../shared/constants/content';
import grid from '../../../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import { ArticleVideoProps } from './typings';

const ArticleVideo = ({ article, video, children }: ArticleVideoProps) => {
  if (!article) {
    return null;
  }
  const ensuredVideo: VideoParagraph = ensureVideoItem(video);

  const isRatgeberArticle = article.subtypeValue === ARTICLE_TYPE_RATGEBER;

  return (
    <div className={classNames('article-video', styles.Wrapper)}>
      {isRatgeberArticle ? (
        <div>
          <p className={classNames(styles.Lead, grid.HiddenSmUp)}>
            {article?.lead || ''}
          </p>
          <AuthorDateBlock article={article} hasContainer={true} />
        </div>
      ) : (
        <div className={grid.Container}>
          <div className={grid.Row}>
            <div className={classNames(grid.ColSm22, grid.ColOffsetSm1)}>
              <p className={classNames(styles.Lead, grid.HiddenSmUp)}>
                {article?.lead || ''}
              </p>
              <AuthorDateBlock article={article} hasContainer={true} />
            </div>
          </div>
        </div>
      )}
      <div
        className={classNames({
          [grid.Container]: !isRatgeberArticle,
        })}
      >
        {children}
      </div>
      <div
        className={classNames({
          [grid.Container]: !isRatgeberArticle,
        })}
      >
        <div className={grid.Row}>
          <div className={grid.ColXs24}>
            <VideoParagraph
              video={ensuredVideo.video as VideoType}
              addClass={styles.Video}
              isFirst
            />
            <ImageCaption
              caption={ensuredVideo.video?.caption}
              credit={ensuredVideo.video?.credit}
              sourceDescription={'Video: '}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleVideo;

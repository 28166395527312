// CONFIG
export const TEASER_IMAGE_IDENTIFIER = 'teaser-image';
export const TEASER_TITLE_LENGTH = 120;

// TEASER M
export const TEASER_M_DEFAULT_IDENTIFIER = 'teaser-m-default';
export const TEASER_M_GUIDE_IDENTIFIER = 'teaser-m-guide';
export const TEASER_M_OPINION_IDENTIFIER = 'teaser-m-opinion';
export const TEASER_M_ADVERTORIAL_IDENTIFIER = 'teaser-m-advertorial';
export const TEASER_M_EXPLAINING_IDENTIFIER = 'teaser-m-explaining';
export const TEASER_M_BRANDREPORT_IDENTIFIER = 'teaser-m-brandreport';

// TEASER HERO
export const TEASER_HERO_ADVERTORIAL_IDENTIFIER = 'teaser-hero-advertorial';
export const TEASER_HERO_BRANDREPORT_IDENTIFIER = 'teaser-hero-brand-report';
export const TEASER_HERO_DEFAULT_IDENTIFIER = 'teaser-hero-default';
export const TEASER_HERO_EXPLAINING_IDENTIFIER = 'teaser-hero-explaining';
export const TEASER_HERO_OPINION_IDENTIFIER = 'teaser-hero-opinion';
export const TEASER_HERO_PORTRAIT_IDENTIFIER = 'teaser-hero-portrait';

// TEASER VIDEO
export const TEASER_VIDEO_IDENTIFIER = 'teaser-video';

// TEASER RECOMMENDATIONS
export const TEASER_RECOMMENDATIONS_IDENTIFIER = 'teaser-recommendations';

// OTHER
export const TEASER_SPONSOR_IDENTIFIER = 'teaser-sponsor-company';

import React from 'react';
import Picture from '../../../../../../../common/components/Picture';
import { STYLE_1X1_140 } from '../../../../../../../shared/constants/images';

const AuthorsImages = ({
  authors,
  authorAvatarStyle,
}: {
  authors: AuthorEdge[];
  authorAvatarStyle: string;
}) => (
  <>
    {authors.map((author, index) => {
      const imageFile = author.node.imageParagraph.image.file || null;
      const { alt = '', relativeOriginPath = '' } = imageFile;
      const focalPointX =
        author.node.imageParagraph.image.file.focalPointX || null;
      const focalPointY =
        author.node.imageParagraph.image.file.focalPointY || null;

      if (!relativeOriginPath) {
        return null;
      }

      return (
        <Picture
          key={`author-image-${author.node.id || index}`}
          relativeOrigin={relativeOriginPath}
          alt={alt}
          className={authorAvatarStyle}
          style_320={STYLE_1X1_140}
          focalPointX={focalPointX}
          focalPointY={focalPointY}
        />
      );
    })}
  </>
);
export default AuthorsImages;

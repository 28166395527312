/* istanbul ignore file */
import { RaschApolloConfig } from '../../../../../shared/decorators/withRaschRouterFactory';
import { PUBLICATION_BEO } from '../../../../../shared/constants/publications';
import { EXPLAINING_TYPE_LEGAL_DICTIONARY } from '../././ExplainingArticles/constants';
import { GET_EXPLAINING_PAGE } from '../././ExplainingArticles/queries';

export const apolloConfig: RaschApolloConfig = {
  options: ({
    params: { char = 'A', category = EXPLAINING_TYPE_LEGAL_DICTIONARY },
  }) => {
    if (char.length > 1) {
      char = char.substring(0, 1);
    }
    return {
      query: GET_EXPLAINING_PAGE,
      variables: {
        char: char.toUpperCase(),
        category: category.charAt(0).toUpperCase() + category.slice(1),
        publication: PUBLICATION_BEO,
      },
    };
  },
};

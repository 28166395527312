import { gql } from '@apollo/client';

export const ministageSearchFragment = gql`
  fragment MinistageSearchFragment on MinistageSearch {
    headline
    subhead
    lead
    image {
      id
      relativeOriginPath
      focalPointX
      focalPointY
    }
  }
`;

import React, { ReactElement } from 'react';
import classNames from 'classnames';
import Link from '../../../../../../../common/components/Link';
import Picture from '../../../../../../../common/components/Picture';
import { mergeClasses } from '../../../../../../../shared/helpers/mergeClasses';
import FullscreenButton from '../../../FullscreenButton';
import ImageCaption from '../ImageCaption';
import { isInLongFormArticleBody } from '../../../../../../shared/helpers/isInLongFormArticleBody';
import {
  TRACKING_CLASS_IMAGE_PARAGRAPH,
  TRACKING_CLASS_PARAGRAPH,
} from '../../../../../../../shared/constants/tracking';
import { MULTI_COLUMNS_PARAGRAPH } from '../../../../../../../shared/constants/paragraphs';
import {
  STYLE_16X9_1180,
  STYLE_3X4_960,
  STYLE_INLINE_IMAGE_1200,
} from '../../../../../../../shared/constants/images';
import { CANNOT_SHOW_IMAGE_MESSAGE, FALLBACK_IMAGE_URL } from './constants';
import { ARTICLE_TYPE_RATGEBER } from '../../../../../../../shared/constants/content';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import { ImageParagraphProps } from './typings';

export type ImageParagraphPropsInner = ImageParagraphProps & {
  heroMediaParagraph?: boolean;
};

const ImageParagraph = ({
  imageParagraph,
  plainImage = false,
  origin = '',
  heroMediaParagraph,
  ...props
}: ImageParagraphPropsInner): ReactElement => {
  if (!imageParagraph || !imageParagraph.image) {
    return null;
  }
  const originalUrl =
    (imageParagraph.image?.showOriginal &&
      imageParagraph.image?.file?.origin) ||
    null;

  const isPortrait =
    imageParagraph.format === 'portrait' ||
    (imageParagraph?.image?.file?.width < imageParagraph?.image?.file?.height &&
      !imageParagraph.fullWidth);

  const isInLongFormArticle = isInLongFormArticleBody(origin);

  const landscapeFormat = isInLongFormArticle
    ? STYLE_16X9_1180
    : STYLE_INLINE_IMAGE_1200;

  const imgStyle = isPortrait ? STYLE_3X4_960 : landscapeFormat;
  let imgComp = imageParagraph.image.credit ? (
    <Picture
      url={originalUrl}
      showOriginal={imageParagraph.image?.showOriginal}
      relativeOrigin={imageParagraph.image?.file?.relativeOriginPath}
      alt={imageParagraph.image?.file?.alt}
      style_320={imgStyle}
      width={imageParagraph?.image?.file?.width}
      height={imageParagraph?.image?.file?.height}
      focalPointX={imageParagraph.image?.file?.focalPointX}
      focalPointY={imageParagraph.image?.file?.focalPointY}
    />
  ) : (
    <Picture
      url={FALLBACK_IMAGE_URL}
      alt={CANNOT_SHOW_IMAGE_MESSAGE}
      style_320={imgStyle}
      width={imageParagraph?.image?.file?.width}
      height={imageParagraph?.image?.file?.height}
    />
  );

  if (!heroMediaParagraph) {
    imgComp = (
      <div
        className={classNames(styles.ImageWrapper, {
          [styles.LongReadLandscapeImage]: isInLongFormArticle && !isPortrait,
        })}
      >
        <FullscreenButton imageId={imageParagraph.id} origin={origin} />{' '}
        {imgComp}
      </div>
    );
  }

  if (imageParagraph.link && imageParagraph.link.path) {
    imgComp = <Link {...imageParagraph.link}>{imgComp}</Link>;
  }

  const isPortraitImage = isPortrait && origin !== MULTI_COLUMNS_PARAGRAPH;

  imgComp =
    (isPortraitImage && (
      <div
        className={classNames(styles.PortraitWrapper, {
          [styles.PortraitWrapperHeroMedia]: heroMediaParagraph,
          [styles.LongReadImage]: isInLongFormArticle,
        })}
      >
        {imgComp}
      </div>
    )) ||
    imgComp;

  if (!plainImage) {
    return (
      <div
        className={classNames(
          TRACKING_CLASS_PARAGRAPH,
          TRACKING_CLASS_IMAGE_PARAGRAPH,
          styles.Wrapper,
          {
            [mergeClasses([grid.ColXl18, grid.ColOffsetXl3])]:
              origin === ARTICLE_TYPE_RATGEBER,
            [mergeClasses([grid.ColSm20, grid.ColOffsetSm2])]:
              !isPortrait && isInLongFormArticle,
            [mergeClasses([
              grid.ColXs18,
              grid.ColOffsetXs3,
              grid.ColOffsetSm4,
              grid.ColSm16,
              grid.ColOffsetMd6,
              grid.ColMd12,
            ])]: isPortrait && isInLongFormArticle,
          },
        )}
      >
        {imageParagraph.title && (
          <h2 className={styles.Title}>{imageParagraph.title}</h2>
        )}
        <div
          className={classNames(styles.ImageWrapper, {
            [styles.LongReadLandscapeImage]: isInLongFormArticle && !isPortrait,
          })}
        >
          {imgComp}
          {!props.hideCaption && imageParagraph.image?.credit && (
            <ImageCaption
              caption={imageParagraph.caption}
              credit={imageParagraph.image?.credit || ''}
              origin={origin}
              suppressSource={imageParagraph.suppressSource}
              addClass={classNames({
                [styles.LongReadImageCaption]: isInLongFormArticle,
                [styles.PortraitCaption]: isPortraitImage,
              })}
            />
          )}
        </div>
      </div>
    );
  }

  return imgComp;
};

export default ImageParagraph;

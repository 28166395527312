import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { LEGAL_DICTIONARY } from '../../../../../../shared/actions/route';
import Link from '../../../../../../../common/components/Link';
import SVGIcon from '../../../SVGIcon';
import {
  SVG_ICONS_TYPE_CHEVRON_LEFT,
  SVG_ICONS_TYPE_CHEVRON_RIGHT,
} from '../../../../../../../shared/constants/svgIcons';
import styles from './styles.legacy.css';

const SCROLL_CHANGE = 150;
const SCROLL_OFFSET = 2;

type MainLinksProps = {
  menu: Menu;
  routePathname: string;
  routeVertical: string;
};

export const isNotExpanded = ({ node }: MenuTreeItemEdge) =>
  !(node && node.link && node.link.expanded);

const MainLinks = ({ menu, routePathname, routeVertical }: MainLinksProps) => {
  const scrollableLinks = useRef(null);
  const [isScrolledToLeft, setIsScrolledToLeft] = useState(true);
  const [isScrolledToRight, setIsScrolledToRight] = useState(false);

  const pathnameSegments = (routePathname || '/').split('/');
  const pathname = pathnameSegments.slice(0, 2).join('/');

  pathnameSegments.shift();
  const isLegalDictionary = routeVertical === LEGAL_DICTIONARY;

  // extract main channel and tweak it for the legal dictionary
  const mainChannel =
    (isLegalDictionary && 'beratung') || // map `rechtslexikon` to beratung
    pathnameSegments[0] || // all others should be handled as they are
    '';

  const linkRefs = useRef<HTMLSpanElement[]>([]);

  let currentMenu = menu?.links;
  // get the correct main channel
  if (menu?.links?.edges) {
    let mainChannelIndex = menu.links.edges.findIndex(
      ({ node }) => node?.link?.path === `/${mainChannel}`,
    );
    // if not found main channel, try to find home
    if (mainChannelIndex === -1) {
      mainChannelIndex = menu.links.edges.findIndex(
        ({
          node: {
            link: { path },
          },
        }) => path === '/',
      );
    }
    if (mainChannelIndex > -1) {
      currentMenu = menu.links.edges[mainChannelIndex].node?.subtree;
    }
  }

  const mapLinks: MenuTreeItemEdge[] = currentMenu?.edges
    .filter(({ node }) => node?.link?.label && node?.link?.path)
    .filter(isNotExpanded);

  useEffect(() => {
    const activeLinkIndex = mapLinks?.findIndex((link) => {
      return pathname === link.node.link?.path;
    });

    setIsScrolledToLeft(scrollableLinks.current?.scrollLeft <= 0);
    setIsScrolledToRight(
      scrollableLinks.current?.scrollLeft +
        scrollableLinks.current?.offsetWidth >=
        scrollableLinks.current?.scrollWidth - SCROLL_OFFSET,
    );

    setTimeout(() => {
      const linkToScroll = activeLinkIndex !== -1 ? activeLinkIndex : 0;
      linkRefs.current[linkToScroll]?.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
      });
    }, 0);
    // eslint-disable-next-line
  }, [pathname]);

  if (
    !menu ||
    !menu.links ||
    !menu.links.edges ||
    menu.links.edges.length < 1
  ) {
    return null;
  }

  const scrollByStep = (direction = 1) => {
    scrollableLinks.current.scrollLeft += SCROLL_CHANGE * direction;
  };

  return (
    <div className={styles.MainLinks}>
      <button
        onClick={() => scrollByStep(-1)}
        className={classNames(styles.IconButton, {
          [styles.Hide]: isScrolledToLeft,
        })}
      >
        <SVGIcon className={styles.Icon} type={SVG_ICONS_TYPE_CHEVRON_LEFT} />
      </button>
      <div
        className={styles.Links}
        ref={scrollableLinks}
        onScroll={(event) => {
          const { scrollLeft, scrollWidth, offsetWidth } =
            event.target as HTMLElement;
          const isOnLeft = scrollLeft <= 0;
          const isOnRight =
            scrollLeft + offsetWidth >= scrollWidth - SCROLL_OFFSET;

          if (isScrolledToLeft !== isOnLeft) {
            setIsScrolledToLeft(isOnLeft);
          }

          if (isScrolledToRight !== isOnRight) {
            setIsScrolledToRight(isOnRight);
          }
        }}
      >
        {mapLinks.map(({ node }, index) => {
          return (
            <span
              key={`navigation-subtree-${index}-${node?.link?.label}`}
              ref={(element) => (linkRefs.current[index] = element)}
            >
              <Link
                data-index={index}
                className={classNames(styles.Link, {
                  [styles.LinkActive]: pathname === node?.link?.path,
                })}
                path={node?.link?.path}
                label={node?.link?.label}
              />
            </span>
          );
        })}
      </div>
      <button
        onClick={() => scrollByStep()}
        className={classNames(styles.IconButton, styles.IconRight, {
          [styles.Hide]: isScrolledToRight,
        })}
      >
        <SVGIcon className={styles.Icon} type={SVG_ICONS_TYPE_CHEVRON_RIGHT} />
      </button>
    </div>
  );
};

export default MainLinks;

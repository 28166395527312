export const typesCode2Name: Record<number, string> = {
  1002: 'Beobachter-Artikel',
  0: 'Merkblatt',
  1: 'Checkliste',
  2: 'Mustervorlage',
  3: 'Gerichtsentscheid',
  5: 'Adressen und Links',
  9: 'Fallbeispiel',
  10: 'Scharf beobachtet',
  1001: 'Grundlage',
};

import { gql } from '@apollo/client';

export const SEARCH_QUERY = gql`
  query Search(
    $query: String!
    $limit: Int
    $offset: Int
    $sort: SearchOrderByField
    $contentTypes: [ContentTypeEnum]
    $publication: PublicationEnum
    $additionalPublications: [PublicationEnum]
  ) @api(name: cms) {
    globalSearch(
      search: $query
      limit: $limit
      offset: $offset
      sort: $sort
      content_types: $contentTypes
      publication: $publication
      additionalPublications: $additionalPublications
    ) {
      count
      edges {
        node {
          ... on Article {
            preferredUri
            id
            title
            shortTitle
            lead
            hasVideo
            subtypeValue: articleType
            toolType
            restrictionStatus
            authors(first: 10) {
              edges {
                node {
                  id
                  name
                  imageParagraph {
                    id
                    image {
                      id
                      file {
                        id
                        alt
                        relativeOriginPath
                        focalPointX
                        focalPointY
                      }
                    }
                  }
                }
              }
            }
            channel {
              id
              title
            }
            teaserImage {
              id
              format
              image {
                id
                file(style: "large") {
                  id
                  alt
                  relativeOriginPath
                  focalPointX
                  focalPointY
                }
              }
            }
            useAutoHyphens
            badgeLabel
            badgeColor
          }
          ... on NativeAdvertising {
            preferredUri
            id
            title
            shortTitle
            lead
            hasVideo
            subtypeValue: advertisingTypeValue
            advertisingTypeLabel
            restrictionStatus
            trackingTeaserImpression
            trackingTeaserClick
            link {
              path
              label
            }
            channel {
              id
              title
              settings {
                hierarchy {
                  count
                  edges {
                    node {
                      id
                      title
                      tid
                    }
                  }
                }
              }
            }
            teaserImage {
              id
              image {
                id
                file(style: "large") {
                  id
                  alt
                  relativeOriginPath
                  focalPointX
                  focalPointY
                }
              }
            }
            useAutoHyphens
          }
          ... on ExplainingArticle {
            id
            hasVideo
            category
            synonyms
            title
            preferredUri
            teaserImage {
              id
              image {
                id
                file(style: "large") {
                  id
                  alt
                  relativeOriginPath
                  focalPointX
                  focalPointY
                }
              }
            }
            useAutoHyphens
          }
            ... on Product {
            id
            gcid
            number
            title
            shortTitle
            summary: description
            showUpdated
            teaserImage {
                id
                format
                image {
                    id
                    file(style: "large") {
                        id
                        alt
                        relativeOriginPath
                        focalPointX
                        focalPointY
                    }
                }
            }
            useAutoHyphens
            link {
                path
            }
        }
        }
      }
    }
  }
`;

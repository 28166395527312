/* istanbul ignore file */
import { RaschApolloConfig } from '../../../../../shared/decorators/withRaschRouterFactory';
import { PUBLICATION_BEO } from '../../../../../shared/constants/publications';
import { GET_KEYWORDS } from './queries';

export const apolloConfig: RaschApolloConfig = {
  options: ({ params: { searchString = 'A' } }) => ({
    query: GET_KEYWORDS,
    variables: {
      searchString: searchString && searchString.toUpperCase(),
      publication: PUBLICATION_BEO,
    },
  }),
};

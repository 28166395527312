import { RaschApolloConfig } from '../../../../../../../shared/decorators/withRaschRouterFactory';
import { PUBLICATION_BEO } from '../../../../../../../shared/constants/publications';
import { GET_SPONSORS } from './queries';

export const apolloConfig: RaschApolloConfig = {
  options: () => {
    return {
      query: GET_SPONSORS,
      variables: {
        publication: PUBLICATION_BEO,
      },
    };
  },
};

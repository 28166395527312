import { UserCockpitMenuItem } from '../../../../../../../common/components/NavigationUserMenu/typings';
export const TYPE_NAVIGATION_USER_MENU_DEFAULT = 'navigation-user-menu/default';
export const TYPE_NAVIGATION_USER_MENU_MODAL = 'navigation-user-menu/modal';

export const links: UserCockpitMenuItem[] = [
  {
    name: 'Merkliste',
    link: '/profile/merkliste',
    iconType: 'IconBookmark',
    trackingClass: 'link-usercockpit-bookmarks',
  },
  {
    name: 'Newsletter',
    link: `${__AUTH0_PROFILE_URI__}#newsletter`,
    iconType: 'IconEnvelope',
    trackingClass: 'link-usercockpit-newsletter',
  },
  {
    name: 'E-Mail-Alerts',
    link: '/profile/alerts',
    iconType: 'IconBell',
    trackingClass: 'link-usercockpit-email-alerts',
  },
  {
    name: 'E-Paper',
    link: 'https://epaper.beobachter.ch/',
    iconType: 'IconNewspaper',
    trackingClass: 'link-usercockpit-epaper',
  },
  {
    name: 'Abos und Services',
    link: __AUTH0_SERVICES_URI__,
    iconType: 'IconServices',
    trackingClass: 'link-usercockpit-account',
  },
  {
    name: 'Profil bearbeiten',
    link: __AUTH0_PROFILE_URI__,
    iconType: 'IconGear',
    trackingClass: 'link-usercockpit-profile',
  },
];

import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import { useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { getRCTrackingSource } from '../../../../../../../shared/helpers/getRCTrackingSource';
import { tealiumTrackEvent } from '../../../../../../../shared/helpers/tealium';
import {
  DEVICE_TYPE_ANDROID,
  getMobileOperatingSystem,
} from '../../../../../../../shared/helpers/utils';
import authStateSelector from '../../../../../../../shared/selectors/authStateSelector';
import headerStateSelector from '../../../../../../../shared/selectors/headerStateSelector';
import locationStateSelector from '../../../../../../../shared/selectors/locationStateSelector';
import navigationStateSelector from '../../../../../../../shared/selectors/navigationStateSelector';
import pianoStateSelector from '../../../../../../../shared/selectors/pianoStateSelector';
import { setNavigationVisible } from '../../../../../../../shared/actions/navigation';
import { HOME, MARKETING_PAGE } from '../../../../../../shared/actions/route';
import Link from '../../../../../../../common/components/Link';
import LinkLegacy from '../../../../../../../common/components/LinkLegacy';
import Navigation from '../../../Navigation';
import RefetchGqlDataLink from '../../../RefetchGqlDataLink';
import SVGIcon from '../../../SVGIcon';
import SearchForm from '../../../Search/components/SearchForm';
import NewSearchForm from '../../../SearchForm';
import UtilityHeaderBar from '../../../UtilityBar/components/UtilityHeaderBar';
import { Auth0 } from '../../../../../../../common/components/Auth0Provider';
import { useSSRContext } from '../../../../../../../common/components/SSRContext';
import scrollStateSelector from '../../../../../../../shared/selectors/scrollStateSelector';
import { AUTH0_LOGIN_CASE_GENERAL } from '../../../../../../../common/components/Auth0Provider/constants';
import {
  ARTICLE_CONTENT_TYPE,
  ARTICLE_TYPE_GUIDE,
  EXPLAINING_ARTICLE_CONTENT_TYPE,
  NATIVE_ADVERTISING_CONTENT_TYPE,
} from '../../../../../../../shared/constants/content';
import {
  SVG_ICONS_TYPE_ARROW_BACK,
  SVG_ICONS_TYPE_BOOK,
  SVG_ICONS_TYPE_MENU,
  SVG_ICONS_TYPE_SEARCH,
  SVG_ICONS_TYPE_USER,
  SVG_ICONS_TYPE_USER_ACTIVE,
} from '../../../../../../../shared/constants/svgIcons';
import { ROUTE_NEWSLETTER } from '../../../../constants';
import {
  UTILITYBAR_CONFIG_ARTICLE,
  UTILITYBAR_CONFIG_ARTICLE_GUIDE,
  UTILITYBAR_OVERLAY_CONFIG_ARTICLE,
} from '../../../../screens/Article/constants';
import {
  UTILITYBAR_CONFIG_EXPLAINING_ARTICLE,
  UTILITYBAR_OVERLAY_CONFIG_EXPLAINING_ARTICLE,
} from '../../../../screens/././ExplainingArticles/constants';
import { NavigationMenuType } from '../../../Navigation/constants';
import { GET_NAVIGATION } from './queries.preload';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
// @ts-ignore
import logo from 'graphics/logo.svg';
import { HeaderInnerComponent, HeaderInnerProps } from './typings';

export type HeaderInnerPropsInner = HeaderInnerProps & {
  contentType: string;
  routePathname: string;
  routeVertical: string;
  visibleNavigation: null | string;
  authState: AuthState;
  setNavigationVisible: (visibleNavigation: string) => void;
  pageMetadata: PianoPageMetadata;
  headerArticleData: ArticleData;
  headerContentType: string;
  isInArticle?: boolean;
  navigationPrimaryMenu?: Menu;
  scrollDirection: string;
};

const HeaderInner: HeaderInnerComponent = ({
  setNavigationVisible,
  routeVertical,
  contentType,
  isCollapsed,
  hasStickiness = true,
  authState,
  routePathname,
  pageMetadata,
  headerArticleData,
  headerContentType,
  isInArticle,
  navigationPrimaryMenu,
  scrollDirection,
}: HeaderInnerPropsInner) => {
  const params = useParams();
  const [isSearchVisible, setSearchVisible] = useState(false);
  const [focusSearchOnMount, setFocusSearchOnMount] = useState(false);

  useEffect(() => {
    setSearchVisible(false);
  }, [authState.isAuthenticated, routePathname]);

  isCollapsed = isCollapsed && scrollDirection === 'down';

  const isSocialBarVisible = [
    ARTICLE_CONTENT_TYPE,
    NATIVE_ADVERTISING_CONTENT_TYPE,
    EXPLAINING_ARTICLE_CONTENT_TYPE,
  ].includes(contentType);
  const isSocialbarInHeader =
    (isCollapsed || !hasStickiness) && isSocialBarVisible;

  let enabledUtilities = UTILITYBAR_CONFIG_ARTICLE;
  let enabledOverlayUtilities = UTILITYBAR_OVERLAY_CONFIG_ARTICLE;

  if (headerContentType === EXPLAINING_ARTICLE_CONTENT_TYPE) {
    enabledUtilities = UTILITYBAR_CONFIG_EXPLAINING_ARTICLE;
    enabledOverlayUtilities = UTILITYBAR_OVERLAY_CONFIG_EXPLAINING_ARTICLE;
  }

  if (headerArticleData.subtypeValue === ARTICLE_TYPE_GUIDE) {
    enabledUtilities = UTILITYBAR_CONFIG_ARTICLE_GUIDE;
  }

  const { isSSR } = useSSRContext();

  const isPWABackButtonShown =
    (!isSSR &&
      !__TESTING__ &&
      (global?.navigator?.['standalone'] ||
        window.matchMedia('(display-mode: standalone)').matches) &&
      getMobileOperatingSystem() !== DEVICE_TYPE_ANDROID &&
      window.location.pathname !== '/') ||
    false;

  const navigateBack = () => {
    if (__CLIENT__) {
      if (global?.history?.state) {
        global.history.back();
      } else {
        window.location.href = '/';
      }
    }
  };

  const isMarketingPageReducedHeader = routeVertical === MARKETING_PAGE;

  if (routeVertical === HOME) {
    pageMetadata.section = 'HOME';
  }

  const source = getRCTrackingSource('direct', pageMetadata);

  const navigationVerticalLinks = navigationPrimaryMenu?.links?.edges?.map(
    (edge: MenuTreeItemEdge) => {
      return edge?.node?.link?.path;
    },
  );

  return (
    <>
      <div
        className={classNames(
          styles.TabsWrapper,
          grid.HideForPrint,
          styles.ScrollWrapper,
          {
            [styles.Hidden]: isMarketingPageReducedHeader,
            [styles.MoveUpTabs]: isCollapsed,
          },
        )}
      >
        <div className={classNames(grid.Container, styles.ScrollContainer)}>
          <div className={styles.Tabs}>
            {navigationPrimaryMenu?.links?.edges?.map(
              (edge: MenuTreeItemEdge, edgeIndex: number) => {
                const link = edge?.node?.link;
                const isActive =
                  (edgeIndex === 0 &&
                    navigationVerticalLinks
                      .slice(1)
                      .every(
                        (verticalLink) =>
                          !routePathname.startsWith(verticalLink),
                      )) ||
                  (edgeIndex !== 0 &&
                    routePathname.startsWith(
                      navigationVerticalLinks[edgeIndex],
                    ));

                return (
                  link && (
                    <Link
                      className={classNames(styles.TabLink, {
                        [styles.TabLinkActive]: isActive,
                        [styles.TabMagazine]:
                          edge?.node?.link?.label === 'Magazin',
                        [styles.TabAdvisory]:
                          edge?.node?.link?.label === 'Beratung' ||
                          edge?.node?.link?.label === 'Rechtsberatung',
                        [styles.TabCommunity]:
                          edge?.node?.link?.label === 'Engagement',
                      })}
                      path={link.path}
                    >
                      {link.label}
                    </Link>
                  )
                );
              },
            )}
          </div>
        </div>
      </div>
      <div
        className={classNames('header-inner', {
          [grid.GridCentered]: isMarketingPageReducedHeader,
        })}
      >
        <div className={grid.Container}>
          <div className={grid.Row}>
            <div
              className={classNames(grid.ColXs24, styles.InnerWrapper, {
                [styles.Collapsed]:
                  isCollapsed && !isMarketingPageReducedHeader,
                [styles.MarketingPage]: isMarketingPageReducedHeader,
              })}
            >
              <div className={styles.BackButtonLogoWrapper}>
                {/* Back Button */}
                {isPWABackButtonShown && !isMarketingPageReducedHeader && (
                  <button
                    className={classNames(styles.Action, styles.BackAction)}
                    onClick={() => navigateBack()}
                    aria-label="Zurück"
                  >
                    <SVGIcon type={SVG_ICONS_TYPE_ARROW_BACK} />
                  </button>
                )}
                <div className={styles.Col}>
                  {!isPWABackButtonShown && (
                    <div className={styles.BrandAndVerticalWrapper}>
                      <button
                        onClick={() => {
                          setNavigationVisible(NavigationMenuType.DEFAULT);
                          setFocusSearchOnMount(false);
                          tealiumTrackEvent({
                            type: 'link',
                            payload: { event_name: 'menu_open' },
                          });
                        }}
                        className={classNames(styles.MenuButton, {
                          [styles.Hidden]: isMarketingPageReducedHeader,
                          [styles.HidingAnimation]: !isCollapsed,
                          [styles.Collapsed]: isCollapsed,
                        })}
                        data-testid="menu-button"
                      >
                        <SVGIcon
                          type={SVG_ICONS_TYPE_MENU}
                          className={styles.SVGWrapper}
                        />
                      </button>

                      <RefetchGqlDataLink
                        path="/"
                        className={styles.BrandWrapper}
                        aria-label="Zur Startseite"
                        onClick={() => setSearchVisible(false)}
                      >
                        <img
                          className={classNames(styles.Logo, {
                            [styles.Collapsed]:
                              isCollapsed && !isMarketingPageReducedHeader,
                          })}
                          src={logo}
                          alt={'Beobachter'}
                          width={219}
                          height={48}
                        />
                      </RefetchGqlDataLink>
                    </div>
                  )}
                </div>
              </div>

              <div
                className={classNames(
                  styles.ButtonsActions,
                  grid.HideForPrint,
                  {
                    [styles.Collapsed]: isCollapsed,
                  },
                )}
              >
                <div
                  className={classNames(styles.Actions, {
                    [styles.Hidden]: isMarketingPageReducedHeader,
                    [styles.MoveUp]: isSocialbarInHeader,
                  })}
                >
                  {/* Search form */}
                  <NewSearchForm
                    initialQuery={params && params.query}
                    placeholder="Suche"
                  />

                  <button
                    className={classNames(
                      grid.HiddenSmUp,
                      styles.Action,
                      styles.SearchAction,
                    )}
                    onClick={() => {
                      setNavigationVisible(NavigationMenuType.DEFAULT);
                      setFocusSearchOnMount(true);
                    }}
                  >
                    <SVGIcon
                      type={SVG_ICONS_TYPE_SEARCH}
                      className={styles.ActionIcon}
                    />
                  </button>

                  {/* Membership link */}
                  <LinkLegacy
                    link={{
                      path: 'mitglied-werden?promo_name=abobutton&promo_position=header',
                    }}
                    className={classNames(
                      styles.Action,
                      styles.MembershipAction,
                      grid.HiddenLgDown,
                    )}
                    aria-label="Mitglied werden"
                  >
                    Mitglied werden
                  </LinkLegacy>

                  {/* Newsletter menu */}
                  <LinkLegacy
                    className={classNames(
                      styles.Action,
                      styles.NewsletterAction,
                      grid.HiddenXlDown,
                    )}
                    link={{
                      path: ROUTE_NEWSLETTER,
                    }}
                  >
                    <>
                      <SVGIcon
                        type={SVG_ICONS_TYPE_BOOK}
                        className={styles.ActionIcon}
                      />
                      Bücher
                    </>
                  </LinkLegacy>
                  {/* User menu */}
                  {authState.isAuthenticated ? (
                    <button
                      key={`user-menu-button-${Math.random()}`}
                      className={classNames(styles.Action, styles.LoginAction, {
                        [styles.Collapsed]: isCollapsed,
                      })}
                      onClick={(): void =>
                        setNavigationVisible(NavigationMenuType.USER)
                      }
                      data-testid="headerinner-openusernavigation-button"
                      aria-label="Benutzermenü öffnen"
                    >
                      <SVGIcon
                        type={SVG_ICONS_TYPE_USER_ACTIVE}
                        className={styles.ActionIcon}
                      />
                      <span
                        className={classNames(
                          grid.HiddenSmDown,
                          styles.LoginLabel,
                        )}
                      >
                        {authState.givenName &&
                          authState.familyName &&
                          `${authState.givenName} ${authState.familyName}`}
                      </span>
                    </button>
                  ) : (
                    <button
                      key={`user-login-button-${Math.random()}`}
                      id="header-login-action"
                      className={classNames(styles.Action, styles.LoginAction, {
                        [styles.Collapsed]: isCollapsed,
                      })}
                      onClick={() =>
                        Auth0.login(AUTH0_LOGIN_CASE_GENERAL, source)
                      }
                      data-testid="headerinner-login-button"
                      aria-label="login"
                    >
                      <SVGIcon
                        type={SVG_ICONS_TYPE_USER}
                        className={styles.ActionIcon}
                      />
                      <span className={grid.HiddenSmDown}>Anmelden</span>
                    </button>
                  )}
                </div>

                {!__TESTING__ && (
                  <UtilityHeaderBar
                    isScrolledToCollapse={isCollapsed}
                    articleData={headerArticleData}
                    isSocialBarVisible={isSocialBarVisible}
                    enabledUtilities={enabledUtilities}
                    enabledOverlayUtilities={enabledOverlayUtilities}
                    showTitle={false}
                    isTopBar
                    hideIconLabel
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <Navigation
          isScrolledToCollapse={isCollapsed}
          isInArticle={isInArticle}
          setFocusSearchOnMount={setFocusSearchOnMount}
          navigationPrimaryMenu={navigationPrimaryMenu}
        >
          {/* Mobile search form */}
          <div className={styles.SearchFormHeaderWrapper}>
            <SearchForm
              initialQuery={params && params.query}
              placeholder="Beobachter durchsuchen"
              addClass={styles.SearchForm}
              searchButtonClass={styles.SearchButtonClass}
              focusOnMount={focusSearchOnMount}
            />
          </div>
        </Navigation>

        {/* Search form */}
        {isSearchVisible && (
          <CSSTransition
            classNames={{
              appear: styles.ToggleSearchAppear,
              appearActive: styles.ToggleSearchAppearActive,
              enter: '',
              exit: '',
            }}
            appear
            timeout={{ enter: 300 }}
          >
            <div className={styles.SearchFormHeaderWrapper}>
              <SearchForm
                initialQuery={params && params.query}
                placeholder="Beobachter durchsuchen"
                addClass={classNames(grid.Container, styles.SearchForm)}
                searchButtonClass={styles.SearchButtonClass}
                focusOnMount
              />
            </div>
          </CSSTransition>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  routePathname:
    locationStateSelector(state).locationBeforeTransitions.pathname,
  routeVertical: locationStateSelector(state).vertical,
  visibleNavigation: navigationStateSelector(state).visibleNavigation,
  authState: authStateSelector(state),
  contentType: headerStateSelector(state).contentType,
  pageMetadata: pianoStateSelector(state).pageMetadata,
  headerArticleData: headerStateSelector(state).articleData,
  headerContentType: headerStateSelector(state).contentType,
  scrollDirection: scrollStateSelector(state).direction,
});

const mapDispatchToProps = {
  setNavigationVisible,
};

const withData = withProps(GET_NAVIGATION);

const withStoreConnection = connect(mapStateToProps, mapDispatchToProps);

export default compose(withStoreConnection, withData)(memo(HeaderInner));

/**
 * Grid Layouts
 */
export const GRID_LAYOUT_ENTITY_QUEUE_COLUMN = 'entityQueueColumn';
export const GRID_LAYOUT_ENTITY_QUEUE_COLUMN_MIXED = 'entityQueueColumnMixed';
export const GRID_LAYOUT_ENTITY_QUEUE_DEFAULT = 'entityQueueDefault';
export const GRID_LAYOUT_ENTITY_QUEUE_DEFAULT_FIRST = 'entityQueueDefaultFirst';
export const GRID_LAYOUT_TEASER_3X2 = 'teaser3x2';
export const GRID_LAYOUT_TEASER_4X2 = 'teaser4x2';
export const GRID_LAYOUT_TEASER_3X2_NO_CONTAINER = 'teaser3x2NoContainer';
export const GRID_LAYOUT_RECOMMENDATIONS = 'teaserRecommendations';
export const GRID_LAYOUT_SHOP_PRODUCT = 'teaserStageShopProduct';
export const GRID_LAYOUT_TEASER_SPONSOR = 'teaserSponsors';
export const GRID_LAYOUT_TEASER_1X18 = 'teaser1x18';
export const GRID_LAYOUT_TEASER_1X18_NO_ADS = 'teaser1x18NoAds';
export const GRID_LAYOUT_TEASER_NEW_RECOMMENDATIONS =
  'teaserNewRecommendations';
export const GRID_LAYOUT_TEASER_SM_COLUMN = 'teaserSMColumn';
export const GRID_LAYOUT_TEASER_SM_ROW = 'teaserSMRow';
export const GRID_LAYOUT_TEASER_WIDE_COLUMN = 'teaserWideColumn';
export const GRID_LAYOUT_TEASER_MOST_READ = 'teaserMostRead';
export const GRID_LAYOUT_CHANNEL_PAGE_DEFAULT = 'channelPageDefault';
export const GRID_LAYOUT_TEASER_AUTHOR = 'ministageAuthors';
export const GRID_LAYOUT_TEASER_BOOKMARK = 'teaserBookmark';
export const GRID_LAYOUT_TEASER_TOOLS = 'teaserTools';
export const GRID_LAYOUT_TEASER_LEGAL_ADVICES = 'teaserLegalAdvices';
export const GRID_LAYOUT_KEYWORDS = 'teaserKeywords';

import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import classNames from 'classnames';
import parseTrackingData from '../../../../../shared/helpers/parseTrackingData';
import { ensureTeaserInterface } from '../../components/Teaser/shared/helpers';
import withParams from '../../../../../shared/decorators/withParams';
import withAppNexus from '../../../../shared/decorators/withAppNexus';
import withHelmet from '../../../../shared/decorators/withHelmet';
import { setStatusCode } from '../../../../../shared/actions/ssr';
import Helmet from '../../components/Helmet';
import TeaserGrid from '../../components/TeaserGrid';
import SortOrder from './components/SortOrder';
import Pager, { PAGER_TYPE_PAGE_LOADER } from '../../components/Pager';
import {
  ROBOTS_META_NOINDEX_FOLLOW_NOODP_NOARCHIVE,
  ROOT_SCHEMA_TYPE_WEB_PAGE,
} from '../../../../../shared/constants/structuredData';
import { GRID_LAYOUT_TEASER_1X18 } from '../../components/TeaserGrid/gridConfigs/constants';
import { ITEMS_PER_PAGE } from './constants';
import { SITE_TITLE } from '../../constants';
import grid from '../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import { SearchProps } from './typings';

type SearchPropsInner = SearchProps & {
  query: string;
  setStatusCode: (code: number) => void;
};

export const renderNothingFoundJsx = (setStatusCode) => {
  if (__SERVER__) {
    setStatusCode(404);
  }

  return (
    <div
      className={classNames(grid.Container, styles.EmptyResultWrapper)}
      data-testid="search-no-results-container"
    >
      <h2>
        Leider wurden keine mit Ihrer Suchanfrage übereinstimmenden Dokumente
        gefunden.
      </h2>
      <span>Für die nächste Suche:</span>
      <ul>
        <li>Achten Sie darauf, dass alle Wörter richtig geschrieben sind.</li>
        <li>Probieren Sie es mit anderen Suchbegriffen.</li>
        <li>Probieren Sie es mit allgemeineren Suchbegriffen.</li>
      </ul>
    </div>
  );
};

const Search = ({
  data,
  page,
  setStatusCode,
  loading,
  query,
}: SearchPropsInner) => {
  const globalSearch = data?.globalSearch;

  const hasResults =
    globalSearch && globalSearch.count && globalSearch.count > 0;

  return (
    <div className="search-page" data-testid="search-page">
      <div className={grid.Container}>
        <Helmet
          title={`${query} | ${SITE_TITLE}`}
          socialMetaValues={{
            field_short_title: query,
            field_short_description: query,
            field_heroimage: '',
            field_lead: query,
          }}
          meta={[
            {
              name: 'robots',
              content: ROBOTS_META_NOINDEX_FOLLOW_NOODP_NOARCHIVE,
            },
          ]}
        />
        <h1 className={styles.Header}>
          Suchergebnisse für:
          <p className={styles.SearchValue}>{query}</p>
        </h1>
      </div>
      {hasResults &&
      Array.isArray(globalSearch.edges) &&
      globalSearch.edges.length > 0 ? (
        <div data-testid="search-results-container">
          <SortOrder />
          <div
            className={classNames(
              'search-result',
              styles.ResultsWrapper,
              grid.Container,
            )}
            data-testid="searchresult-wrapper"
          >
            <div className={grid.Row}>
              <div
                className={classNames(
                  grid.ColXs24,
                  grid.ColOffsetMd5,
                  grid.ColMd14,
                  grid.ColOffsetXl4,
                  grid.ColXl16,
                )}
              >
                <TeaserGrid
                  layout={GRID_LAYOUT_TEASER_1X18}
                  items={ensureTeaserInterface(globalSearch.edges)}
                />
              </div>
            </div>
          </div>
          <div className={classNames(grid.Container, styles.PagerWrapper)}>
            <Pager
              key={`search-result-pager-${query}`}
              component={PAGER_TYPE_PAGE_LOADER}
              currentPage={page}
              itemsCount={globalSearch.count}
              itemsPerPage={ITEMS_PER_PAGE}
            />
          </div>
        </div>
      ) : (
        <>{(!loading && renderNothingFoundJsx(setStatusCode)) || null}</>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  setStatusCode,
};

const withStoreConnection = connect(null, mapDispatchToProps);

export default compose(
  withParams,
  withStoreConnection,
  withHelmet({
    getNodesCount: (mapProps) => mapProps?.data?.globalSearch?.count || 0,
    pageSize: ITEMS_PER_PAGE,
    rootSchemaType: ROOT_SCHEMA_TYPE_WEB_PAGE,
    getNodes: (mapProps) => mapProps?.data?.globalSearch?.edges || [],
  }),
  withAppNexus({
    parseTrackingData: (props) =>
      parseTrackingData({
        ...props,
        articleType: 'LandingPage',
        contentType: 'Search',
        articleId: `search_${props.query}`,
        subsection: props.query,
      }),
  }),
)(Search);

import React, { ReactElement } from 'react';
import classNames from 'classnames';
import {
  DATE_FORMAT_MONTHNAME_FULL,
  getFormattedElapsedDate,
} from '../../../../../shared/helpers/dateTimeElapsed';
import Link from '../../../../../common/components/Link';
import Picture from '../../../../../common/components/Picture';
import TestFragment from '../../../../../shared/tests/components/TestFragment';
import { ARTICLE_TYPE_RATGEBER } from '../../../../../shared/constants/content';
import { STYLE_1X1_140 } from '../../../../../shared/constants/images';
import {
  CONTENT_TYPE_LABEL_NONE,
  CONTENT_TYPE_LABEL_NOT_UPDATABLE,
  CONTENT_TYPE_LABEL_OPEN,
} from '../../screens/Article/constants';
import styles from './styles.legacy.css';
import type {
  AuthorDateBlockProps,
  AuthorProps,
  AuthorsImagesProps,
  AuthorsProps,
  CreateDateProps,
  ModifingyAuthorProps,
  ModifingyAuthorsProps,
} from './typings';

type SourceProps = {
  source: string | null;
  addClass?: string;
};

export const Source = ({
  source,
  addClass,
}: SourceProps): ReactElement | null =>
  (source && (
    <div
      className={classNames({ [addClass]: !!addClass })}
    >{`Quelle: ${source}`}</div>
  )) ||
  null;

const CreateDate = ({
  publicationDate,
  showUpdated,
  changeDate,
  contentTypeLabel,
  createDate,
  isAuthorVisible = false,
}: CreateDateProps): ReactElement | null => {
  const isPublicationDateVisible: boolean =
    !!publicationDate &&
    !(
      showUpdated &&
      changeDate &&
      CONTENT_TYPE_LABEL_OPEN === contentTypeLabel
    );

  const isModificationDateVisible: boolean =
    !!showUpdated &&
    !!changeDate &&
    [
      CONTENT_TYPE_LABEL_OPEN,
      CONTENT_TYPE_LABEL_NOT_UPDATABLE,
      CONTENT_TYPE_LABEL_NONE,
    ].includes(contentTypeLabel);

  return (
    <span data-testid="dates-wrapper" className={styles.Dates}>
      {isPublicationDateVisible ? (
        <>
          {(isAuthorVisible && <br />) || null}
          <span
            className={styles.PublicationDate}
            data-testid="publication-date-wrapper"
          >
            <span data-testid="publication-date-label">
              Veröffentlicht&nbsp;
            </span>
            {getFormattedElapsedDate({
              createDate: publicationDate,
              dateFormat: DATE_FORMAT_MONTHNAME_FULL,
            })}
          </span>
        </>
      ) : null}
      {isModificationDateVisible ? ', ' : null}
      {isModificationDateVisible ? (
        <>
          {(isAuthorVisible && <br />) || null}

          <span data-testid="modification-date-wrapper">
            <span data-testid="modification-date-label">
              aktualisiert&nbsp;
            </span>
            {getFormattedElapsedDate({
              changeDate: changeDate,
              dateFormat: DATE_FORMAT_MONTHNAME_FULL,
            })}
          </span>
        </>
      ) : null}
      {(!isPublicationDateVisible && !isModificationDateVisible && (
        <>
          {(isAuthorVisible && <br />) || null}
          Veröffentlicht&nbsp;
          {getFormattedElapsedDate({
            createDate: publicationDate || createDate,
            dateFormat: DATE_FORMAT_MONTHNAME_FULL,
          })}
        </>
      )) ||
        null}
    </span>
  );
};

// exported because whole component shouldn't be used in NativeAdvertising
export const Authors = ({
  authors,
  addClass = '',
  addClassItem = '',
}: AuthorsProps): ReactElement => (
  <TestFragment data-testid="author-container">
    {authors &&
      authors.map(
        (item: AuthorEdge, index: number): ReactElement => (
          <Author
            item={item}
            index={index}
            isFirst={index === 0}
            isLast={index === authors.length - 1}
            addClass={addClass}
            addClassItem={addClassItem}
            key={`authors-${index}`}
          />
        ),
      )}
  </TestFragment>
);

const Author = ({
  item,
  index,
  isFirst,
  isLast,
  addClass,
  addClassItem,
}: AuthorProps) => {
  if (!item.node || !item.node.name) {
    return null;
  }

  const hasProfilePage = item.node?.hasProfilePage && item.node?.preferredUri;

  return (
    <span key={`author-${item.node.id}-${index}`} className={addClass}>
      {!isFirst && !isLast && <>,&nbsp;</>}
      {isLast && !isFirst && <>&nbsp;und&nbsp;</>}
      <Link
        className={classNames(addClassItem, {
          [styles.ActiveLink]: hasProfilePage,
          [styles.InActiveLink]: !hasProfilePage,
        })}
        path={hasProfilePage ? item.node.preferredUri : null}
      >
        <span itemProp="author">{item.node?.name}</span>
      </Link>
    </span>
  );
};

const ModifyingAuthors = ({
  authors,
  addClass = '',
  addClassItem = '',
}: ModifingyAuthorsProps): ReactElement => (
  <TestFragment data-testid="modifying-author-container">
    {authors &&
      authors.map(
        (item: AuthorEdge, index: number): ReactElement => (
          <ModifyingAuthor
            item={item}
            index={index}
            isFirst={index === 0}
            isLast={index === authors.length - 1}
            addClass={addClass}
            addClassItem={addClassItem}
            key={`modifying-authors-${index}`}
          />
        ),
      )}
  </TestFragment>
);

const ModifyingAuthor = ({
  item,
  index,
  isFirst,
  isLast,
  addClass,
  addClassItem,
}: ModifingyAuthorProps): ReactElement => {
  const hasProfilePage = item.node?.hasProfilePage && item.node?.preferredUri;

  return (
    <span key={`modifying-author-${index}`} className={addClass}>
      {isFirst && ' durch '}
      {!isFirst && !isLast && ', '}
      {isLast && !isFirst && ' und '}

      <Link
        className={classNames(addClassItem, {
          [styles.ActiveLink]: hasProfilePage,
          [styles.InActiveLink]: !hasProfilePage,
        })}
        path={hasProfilePage ? item.node.preferredUri : null}
      >
        <span itemProp="author">{item.node?.name}</span>
      </Link>
    </span>
  );
};

const AuthorsImages = ({ authors }: AuthorsImagesProps) => (
  <>
    {authors.map((author, index) => {
      const imageFile = author.node.imageParagraph.image.file || null;
      const { alt = '', relativeOriginPath = '' } = imageFile;
      const focalPointX =
        author.node.imageParagraph.image.file.focalPointX || null;
      const focalPointY =
        author.node.imageParagraph.image.file.focalPointY || null;
      const isLast = index === authors.length - 1;

      if (!relativeOriginPath) {
        return null;
      }

      const hasProfilePage =
        author.node.hasProfilePage && author.node.preferredUri;

      return (
        <Link
          key={author.node.id}
          path={hasProfilePage ? author.node.preferredUri : null}
        >
          <Picture
            key={`author-image-${author.node.id || index}`}
            relativeOrigin={relativeOriginPath}
            alt={alt}
            className={classNames(styles.AuthorAvatar, {
              [styles.AuthorAvatarLast]: isLast,
            })}
            style_320={STYLE_1X1_140}
            focalPointX={focalPointX}
            focalPointY={focalPointY}
          />
        </Link>
      );
    })}
  </>
);

export const AuthorDateBlock = ({
  article,
  hasContainer,
  isAlwaysLeftAligned,
  addClass,
}: AuthorDateBlockProps): ReactElement | null => {
  if (!article) {
    return null;
  }

  const hasAuthors = article.authors?.edges?.length > 0;
  const hasMoreThanTwoAuthors = article.authors?.edges?.length > 2;

  const authorsWithImages =
    hasAuthors && !hasMoreThanTwoAuthors
      ? article.authors.edges
          .filter(
            (author) =>
              !!author.node?.imageParagraph?.image?.file?.relativeOriginPath,
          )
          .slice(0, 2)
      : [];
  const showAuthorsImages = authorsWithImages.length > 0;

  return (
    <div
      data-testid="main-container"
      className={classNames(styles.AuthorWrapper, {
        [styles.AuthorWrapperMargin]:
          !hasContainer && article.subtypeValue !== ARTICLE_TYPE_RATGEBER,
        [styles.LeftAligned]: isAlwaysLeftAligned || showAuthorsImages,
        [addClass]: !!addClass,
      })}
    >
      {showAuthorsImages && <AuthorsImages authors={authorsWithImages} />}

      <div>
        {hasAuthors && (
          <Authors
            authors={article.authors.edges}
            addClass={styles.Author}
            addClassItem={styles.AuthorLink}
          />
        )}

        <CreateDate
          publicationDate={article.publicationDate}
          showUpdated={article.showUpdated}
          createDate={article.createDate}
          changeDate={article.changeDate}
          contentTypeLabel={article.contentTypeLabel}
          isAuthorVisible={hasAuthors}
        />

        {article.modifyingAuthors?.edges?.length > 0 && (
          <ModifyingAuthors
            authors={article.modifyingAuthors.edges}
            addClass={styles.ModifyingAuthor}
            addClassItem={styles.ModifyingAuthorLink}
          />
        )}

        {article.source && (
          <Source
            source={article.source}
            addClass={classNames(styles.Source, {
              [styles.LeftAligned]: isAlwaysLeftAligned || showAuthorsImages,
            })}
          />
        )}
      </div>
    </div>
  );
};

export default AuthorDateBlock;

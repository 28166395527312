import documentCheckFormParagraphFactory from '../../../../../../../common/components/Paragraphs/components/DocumentCheckFormParagraph/factory';
import { displayErrorToast } from '../../../Toast';
import InputField from '../WebformParagraph/components/InputField';
import Button from '../../../ButtonWithLoading';
import FileField from '../WebformParagraph/components/FileField';
import LoginForm from '../../../LoginForm';
import { DEFAULT_PUBLICATION } from '../../../../constants';
import styles from './styles.legacy.css';

const DocumentCheckFormParagraph = documentCheckFormParagraphFactory({
  displayErrorToast,
  publication: DEFAULT_PUBLICATION,
  styles: {
    SubHeadline: styles.SubHeadline,
    Info: styles.Info,
    ButtonWrapper: styles.ButtonWrapper,
    InputWrapper: styles.InputWrapper,
    Step: styles.Step,
    ErrorLabel: styles.ErrorLabel,
    Disabled: styles.Disabled,
  },
  InputField,
  Button,
  FileField,
  LoginForm: LoginForm,
});

export default DocumentCheckFormParagraph;

/* istanbul ignore file */
import { RaschApolloConfig } from '../../../../../shared/decorators/withRaschRouterFactory';
import { PUBLICATION_BEO } from '../../../../../shared/constants/publications';
import { EXPLAINING_TYPE_LEGAL_DICTIONARY } from './constants';
import { GET_EXPLAINING_PAGE } from './queries';

export const apolloConfig: RaschApolloConfig = {
  options: ({
    params: { char = 'A', category = EXPLAINING_TYPE_LEGAL_DICTIONARY },
  }) => {
    return {
      query: GET_EXPLAINING_PAGE,
      variables: {
        char: char && `${char.toUpperCase()}`,
        category: category.charAt(0).toUpperCase() + category.slice(1),
        publication: PUBLICATION_BEO,
      },
      skip: char.length > 1,
    };
  },
};

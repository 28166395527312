import {
  UTILITY_TYPE_SHARE,
  UTILITY_TYPE_EMAIL,
  UTILITY_TYPE_FACEBOOK,
  UTILITY_TYPE_WHATSAPP,
  UTILITY_TYPE_TWITTER,
  UTILITY_TYPE_MESSENGER,
  UTILITY_TYPE_GETPOCKET,
  UTILITY_TYPE_PINTEREST,
  UTILITY_TYPE_BOOKMARKS,
} from '../../components/UtilityBar/constants';

export const VIDEO_PAGE = 'video-page';

export const UTILITYBAR_CONFIG_VIDEO = [
  UTILITY_TYPE_SHARE,
  UTILITY_TYPE_BOOKMARKS,
];

export const UTILITYBAR_OVERLAY_CONFIG_VIDEO = [
  UTILITY_TYPE_EMAIL,
  UTILITY_TYPE_FACEBOOK,
  UTILITY_TYPE_MESSENGER,
  UTILITY_TYPE_WHATSAPP,
  UTILITY_TYPE_PINTEREST,
  UTILITY_TYPE_TWITTER,
  UTILITY_TYPE_GETPOCKET,
];

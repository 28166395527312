import React from 'react';
import { compose } from 'redux';
import withHelmet from '../../../../shared/decorators/withHelmet';
import ExplainingArticles from '../ExplainingArticles';
import { ROOT_SCHEMA_TYPE_WEBSITE } from '../../../../../shared/constants/structuredData';
import { EXPLAINING_TYPE_LEGAL_DICTIONARY } from '../././ExplainingArticles/constants';

const LegalDictionary = (props) => {
  return (
    <ExplainingArticles
      {...props}
      category={EXPLAINING_TYPE_LEGAL_DICTIONARY}
    />
  );
};

export default compose(
  withHelmet({
    getNode: (mapProps) =>
      mapProps.data?.environment?.routeByPath?.object || null,
    rootSchemaType: ROOT_SCHEMA_TYPE_WEBSITE,
  }),
)(LegalDictionary);

import React, { ReactElement } from 'react';
import TestFragment from '../../../../../../../../src/shared/tests/components/TestFragment';
import { getAlertItemTypeByTypename } from '../../../../../../../common/components/SubscribeButton/helper';
import SubscribeButton from '../../../../components/SubscribeButton';
import Link from '../../../../../../../common/components/Link';
import styles from './styles.legacy.css';
import { KeywordListProps } from './typings';

const KeywordList = ({ list }: KeywordListProps): ReactElement => {
  if (
    !list ||
    !list.edges ||
    !Array.isArray(list.edges) ||
    list.edges.length === 0
  ) {
    return null;
  }

  const filteredList = list.edges
    .filter((edge) => !!edge.node)
    .map((edge) => edge.node);

  return (
    <TestFragment data-testid="keywordlist-elementlist-wrapper">
      {filteredList.map((item) => {
        const { __typename: typeName, id, label } = item;

        if (!label || !id || !typeName) {
          return null;
        }

        return (
          <div
            key={id}
            className={styles.KeywordListItem}
            data-testid="keyword-list-item-wrapper"
          >
            <Link
              path={item.preferredUri}
              label={label}
              className={styles.Label}
            />

            <SubscribeButton
              theme="light"
              label={label || ''}
              type={getAlertItemTypeByTypename(typeName)}
              id={Number(item.tid)}
            />
          </div>
        );
      })}
    </TestFragment>
  );
};

export default KeywordList;

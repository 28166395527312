export const FETCH_TIMESLOTS_ERROR_ID = 'toast/fetch-timeslots-error';
export const SUBMIT_BOOKING_ERROR_ID = 'toast/submit-booking-error';

export const ERROR_FIELD_CLASSNAME = 'has-error';
export const DATE_FIELD_ID = 'date';
export const PHONE_FIELD_ID = 'phone';
export const TIME_FIELD_ID = 'time';
export const BOOKING_PAGE_DIVORCE = 'DIVORCE';
export const BOOKING_PAGE_INHERITANCE = 'INHERITANCE';

export const errorMessages = {
  timeSlots:
    'Bitte wählen Sie eine Uhrzeit aus, an der Sie zurückgerufen werden möchten.',
  timeSlotsAlreadyBooked:
    'Der von Ihnen gewünschte Termin wurde soeben von jemand anderem gebucht. Bitte wählen Sie einen neuen Termin aus.',
  phoneEmpty: 'Bitte geben Sie Ihre Telefonnummer an',
  phoneWrong:
    'Bitte überprüfen Sie das Format und verwenden Sie die Formatierung „0791112233“ oder "0041 79 111 22 33"',
  date: 'Bitte wählen Sie ein Datum',
};

export const defaultErrorMessage =
  'Dieser Dienst ist im Moment leider nicht verfügbar. Bitte versuchen Sie es später noch einmal.';

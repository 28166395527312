export const SUBMIT_BOOKING_ERROR_ID = 'toast/submit-booking-error';

export const ERROR_FIELD_CLASSNAME = 'has-error';
export const PHONE_FIELD_ID = 'phone';

export const errorMessages = {
  phoneEmpty: 'Bitte geben Sie Ihre Telefonnummer an',
  descriptionEmpty: 'Bitte füllen Sie die Pflichtfelder aus',
  phoneWrong:
    'Bitte überprüfen Sie das Format und verwenden Sie die Formatierung „0791112233“ oder "0041 79 111 22 33"',
  documentMissing: 'Bitte laden Sie ein Dokument hoch',
};

export const defaultErrorMessage =
  'Dieser Dienst ist im Moment leider nicht verfügbar. Bitte versuchen Sie es später noch einmal.';

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { assembleAkamaiImgUrl } from '../../../../../../../../../common/components/Picture/helpers';
import locationStateSelector from '../../../../../../../../../shared/selectors/locationStateSelector';
import Link from '../../../../../../../../../common/components/Link';
import TestFragment from '../../../../../../../../../shared/tests/components/TestFragment';
import Icon from '../../../../../Icon';
import { isInsideColumn } from '../../../../../../../../shared/helpers/isInsideColumn';
import {
  TRACKING_CLASS_MINISTAGE_LISTICLE_PARAGRAPH,
  TRACKING_CLASS_PARAGRAPH,
} from '../../../../../../../../../shared/constants/tracking';
import grid from '../../../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import { MinistageLinkProps, MinistageListicleProps } from './typings';

export type MinistageListiclePropsInner = MinistageListicleProps;

const LINK_SIZE = 3;

const MinistageLink = ({
  isExpanded,
  isInColumn,
  image,
  item,
  index,
}: MinistageLinkProps) => {
  const clientUrl = useSelector(
    (state) => locationStateSelector(state).clientUrl,
  );

  if (!item || !item.node || !item.node.path || !item.node.label) {
    return null;
  }

  const isHiddenOnMobile: boolean = !isExpanded && index >= LINK_SIZE;
  const isHiddenOnGuiderArticle: boolean = isHiddenOnMobile && isInColumn;

  const headerImgUrl = assembleAkamaiImgUrl({
    relativeOriginPath: image?.relativeOriginPath,
    width: 480, // large
    height: 0,
    clientUrl,
  });

  return (
    <div
      className={classNames(styles.LinkWrapper, {
        [grid.HiddenSmDown]: isHiddenOnMobile,
        [styles.HiddenOnGuideArticle]: isHiddenOnGuiderArticle,
      })}
      key={index}
    >
      <Link className={styles.Link} {...item.node}>
        <>
          <span className={styles.Rank}>{index + 1}.</span>
          <span className={styles.Text}>
            <span className={styles.TextInner}>{item.node.label}</span>
          </span>
        </>
      </Link>
      {index === 0 && headerImgUrl && (
        <img
          className={styles.Image}
          src={headerImgUrl}
          alt={image?.alt || ''}
        />
      )}
    </div>
  );
};

const MinistageListicle = ({
  ministageListicle,
  origin,
}: MinistageListiclePropsInner) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const links =
    (ministageListicle.links && ministageListicle.links.edges) || null;
  const isInColumn = isInsideColumn(origin);

  if (!links) {
    return null;
  }

  const name = ministageListicle?.name || 'Artikel';

  return (
    <div
      className={classNames(
        TRACKING_CLASS_PARAGRAPH,
        TRACKING_CLASS_MINISTAGE_LISTICLE_PARAGRAPH,
        styles.WrapperLarge,
        {
          [styles.WrapperInGuideArticle]: isInColumn,
        },
      )}
    >
      <div className={styles.Wrapper}>
        <div className={styles.InnerTitle}>
          <div className={styles.Title}>
            {ministageListicle.headline && (
              <span className={styles.TitleText}>
                {ministageListicle.headline}
              </span>
            )}
            <span className={styles.Name}>
              <TestFragment data-testid="ministage-name">{name}</TestFragment>
            </span>
          </div>
        </div>
        <div className={styles.Inner}>
          <div className={styles.Links}>
            {links.map((item, index) => (
              <MinistageLink
                isExpanded={isExpanded}
                isInColumn={isInColumn}
                image={ministageListicle.image}
                key={`ministage-link-${index}`}
                item={item}
                index={index}
              />
            ))}
          </div>
          <div className={styles.Actions}>
            {!isExpanded && (
              <button
                className={styles.Expand}
                onClick={() => setIsExpanded(true)}
              >
                <span>Ganze Liste zeigen</span>
                <Icon addClass={styles.ChevronDown} type="IconChevronDown" />
              </button>
            )}
            {ministageListicle.callToActionLink && (
              <Link
                {...ministageListicle.callToActionLink}
                className={styles.CallToAction}
              >
                <>
                  <span className={styles.CallToActionText}>
                    {ministageListicle.callToActionLink.label}
                  </span>
                  <Icon addClass={styles.ArrowRight} type="IconArrowRight" />
                </>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MinistageListicle;

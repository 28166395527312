import { breadcrumbItems } from '../constants';
import { LegalAdviceCategory } from '../typings';

function prepareBreadcrumbItems(category: LegalAdviceCategory) {
  const categoryItems = JSON.parse(JSON.stringify(breadcrumbItems));
  const categoryBreadcrumb: any = [{ node: { label: category.title } }];
  let tempCategory = category.parent;
  while (tempCategory?.level > 0) {
    categoryBreadcrumb.push({
      node: {
        label: tempCategory.title,
        link: tempCategory.path,
        id: tempCategory.level + 1,
      },
    });
    tempCategory = tempCategory.parent;
  }
  categoryBreadcrumb.reverse().forEach((item) => {
    categoryItems.edges.push(item);
  });

  return categoryItems;
}

export function legalAdviceCategoryBreadcrumbsData(data: any): any {
  if (data?.legalAdvice?.args?.query) {
    data.breadcrumbsData = {
      activeMenuTrail: breadcrumbItems,
      title: 'Suche',
    };
  } else {
    const category = data?.legalAdvice?.category;
    if (category?.level > 0) {
      data.breadcrumbsData = {
        activeMenuTrail: prepareBreadcrumbItems(category),
      };
    }
  }
}

import { ROUTE_HOME_BEO } from '../../../shared/constants/publications';

export const MAIN_CONTENT_ID = 'main';
export const SITE_TITLE = 'Beobachter';
export const DEFAULT_PUBLICATION = 'BEO';
export const MODAL_ROOT_ID = 'modal-root';
export const ADMEIRA_IN_READ_VIDEO_WRAPPER_ID = 'admeira-inread-video-slot';
export const BRIGHTCOVE_ACCOUNT_ID = '2112711546001';
export const BRIGHTCOVE_PLAYER_ID = 'B1ew2V5JZ';

export const URL_BEOBACHTER_SHOP = 'https://shop.beobachter.ch';

export const CHANNEL_TYPE_VIDEO_BLOG = 'video_blog';

export const SOCIAL_MEDIA_LINK_FACEBOOK =
  'https://www.facebook.com/beobachtermagazin';
export const SOCIAL_MEDIA_LINK_INSTAGRAM =
  'https://www.instagram.com/beobachtermagazin/';
export const SOCIAL_MEDIA_LINK_LINKEDIN =
  'https://www.linkedin.com/company/beobachtermagazin';
export const SOCIAL_MEDIA_LINK_TWITTER = 'https://twitter.com/BeobachterRat';
export const SOCIAL_MEDIA_LINK_TIKTOK =
  'https://www.tiktok.com/@beobachtermagazin';
export const SOCIAL_MEDIA_LINK_GOOGLE_NEWS =
  'https://news.google.com/publications/CAAqBwgKMI-VlgswormtAw?hl=de&gl=CH&ceid=CH%3Ade';

export const ROUTE_LOGOUT = 'logout';
export const ROUTE_VIDEOS = 'videos';
export const ROUTE_HOME = ROUTE_HOME_BEO;
export const ROUTE_BRAND_REPORT = 'brandreport';
export const ROUTE_ACCOUNT = 'account';
export const ROUTE_PROFILE = 'profile';
export const ROUTE_LEGAL_ADVICE_PARENT = 'beratung';
export const ROUTE_LEGAL_ADVICE = `${ROUTE_LEGAL_ADVICE_PARENT}/rechtsratgeber`;
export const ROUTE_ALERTS = 'profile/alerts';
export const ROUTE_UNSUBSCRIBE_EMAIL_ALERTS = `${ROUTE_ALERTS}/unsubscribe/email`;
export const ROUTE_UNSUBSCRIBE_EMAIL_ALERTS_ONESIGNAL = `${ROUTE_UNSUBSCRIBE_EMAIL_ALERTS}/:oneSignalExternalId`;
export const ROUTE_SUBSCRIPTIONS = 'abonnements';
export const ROUTE_NEWSLETTER =
  'https://shop.beobachter.ch/buecher?promo_name=buchshop-button&promo_position=header';
export const ROUTE_BOOKMARKS = 'profile/merkliste';
export const ROUTE_ONMEDA = 'gesundheit/:category/list';
export const ROUTE_ONMEDA_CHAR = `${ROUTE_ONMEDA}/:char`;
export const ROUTE_LEGAL_DICTIONARY = 'rechtslexikon/list';
export const ROUTE_LEGAL_DICTIONARY_CHAR = `${ROUTE_LEGAL_DICTIONARY}/:char`;
export const ROUTE_SEARCH = 'suche/:query';
export const ROUTE_FILTER_SEARCH = '/suche/:filter/:query';
export const ROUTE_KEYWORDS = 'stichworte/list';
export const ROUTE_KEYWORDS_SEARCH = `${ROUTE_KEYWORDS}/:searchString`;
export const ROUTE_451 = '451';
export const ROUTE_STYLEGUIDE = 'styleguide';
export const ROUTE_STYLEGUIDE_PARAGRAPHS = 'styleguide/paragraphs';
export const ROUTE_STYLEGUIDE_TYPOGRAPHY = 'styleguide/typography/*';
export const ROUTE_AUTHORS = 'unsere-redaktion';
export const ROUTE_LATEST = 'latest';

export const GOOGLE_RECAPTCHA_KEY = '6LeD_eAUAAAAAOHmZGoWdcVUGiq8qE-xsgxO4KpT';

export const ROUTES_WITH_CUSTOM_PIANO_TRACKING = [ROUTE_451];

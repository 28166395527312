import { RaschApolloConfig } from '../../../../../shared/decorators/withRaschRouterFactory';
/* istanbul ignore file */
import {
  GLOBAL_SEARCH_FILTER_ARTICLE,
  GLOBAL_SEARCH_FILTER_EXPLAINING_ARTICLE,
  GLOBAL_SEARCH_FILTER_NATIVE_ADVERTISING,
  GLOBAL_SEARCH_FILTER_PRODUCT,
  GLOBAL_SEARCH_SORT_BY_RELEVANCE,
} from '../../../../../shared/constants/globalSearch';
import { ITEMS_PER_PAGE } from './constants';
import { SEARCH_QUERY } from './queries';

export const apolloConfig: RaschApolloConfig = {
  options: ({ location, params }) => {
    const page = location.query?.page || 1;
    const sort = location.query?.sort || GLOBAL_SEARCH_SORT_BY_RELEVANCE;
    const { query = '', filter = '' } = params;
    let contentTypes = [
      GLOBAL_SEARCH_FILTER_PRODUCT,
      GLOBAL_SEARCH_FILTER_ARTICLE,
      GLOBAL_SEARCH_FILTER_NATIVE_ADVERTISING,
      GLOBAL_SEARCH_FILTER_EXPLAINING_ARTICLE,
    ];
    let publication = 'BEO';
    let additionalPublications = ['GDR'];
    if (filter === 'buch') {
      contentTypes = [GLOBAL_SEARCH_FILTER_PRODUCT];
    }
    if (filter === 'rr') {
      publication = 'GDR';
      additionalPublications = [];
    }
    return {
      query: SEARCH_QUERY,
      variables: {
        query: query && `${query}*`,
        limit: ITEMS_PER_PAGE,
        offset: (page - 1) * ITEMS_PER_PAGE,
        sort,
        contentTypes,
        publication,
        additionalPublications,
      },
      skip: !query,
    };
  },
};

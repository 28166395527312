import { SECTION_PARAGRAPH, TEXT_PARAGRAPH } from '../constants/paragraphs';
import { HeadingElement } from '../../common/components/TableOfContents/typings';

const getHeadingsFromParagraphs = (
  paragraphs,
): (HeadingElement | HeadingElement[])[] => {
  return paragraphs
    ?.filter(
      (paragraph: TextParagraph | SectionParagraph) =>
        (paragraph.__typename === TEXT_PARAGRAPH && !!paragraph.header) ||
        paragraph.__typename === SECTION_PARAGRAPH,
    )
    .map((navigableParagraph: TextParagraph | SectionParagraph) => {
      if (navigableParagraph.__typename === SECTION_PARAGRAPH) {
        if (navigableParagraph.title) {
          return [
            {
              anchorLink:
                navigableParagraph.anchorId ||
                navigableParagraph.title.replace(/\s/g, ''),
              text: navigableParagraph.title,
              isSectionTitle: true,
            },
            ...getHeadingsFromParagraphs(navigableParagraph.body),
          ];
        }
        return getHeadingsFromParagraphs(navigableParagraph.body);
      }
      return {
        anchorLink:
          navigableParagraph.anchorId ||
          navigableParagraph.header.replace(/\s/g, ''),
        text: navigableParagraph.header,
      };
    });
};

export default getHeadingsFromParagraphs;

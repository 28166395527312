import React from 'react';
import classNames from 'classnames';
import useRaschRouterLocation from '../../../../../../../shared/hooks/useRaschRouterLocation';
import Link from '../../../../../../../common/components/Link';
import { GLOBAL_SEARCH_SORT_BY_RELEVANCE } from '../../../../../../../shared/constants/globalSearch';
import styles from './styles.legacy.css';
import { SortOrderItem } from './typings';

export const DEFAULT_SORT_FIELD = 'ModificationDate';

const sortItems: SortOrderItem[] = [
  {
    name: 'Relevanz',
    sort: GLOBAL_SEARCH_SORT_BY_RELEVANCE,
  },
  {
    name: 'Aktualität',
    sort: DEFAULT_SORT_FIELD,
  },
];

const getHref = (
  pathname: string,
  query: Record<string, string>,
  sort: string,
) => {
  if (!sort || !pathname || !query) {
    return undefined;
  }

  const searchQuery = {
    ...query,
    sort,
  };

  const search = Object.keys(searchQuery)
    .map((value) => `${value}=${searchQuery[value]}`)
    .join('&');

  return `${pathname}${(search && '?' + search) || ''}`;
};

const SortOrder = () => {
  const { pathname, query } = useRaschRouterLocation();
  const currentSortOrder = query?.sort || GLOBAL_SEARCH_SORT_BY_RELEVANCE;

  return (
    <div className={classNames('sort-order', styles.Wrapper)}>
      <div className={styles.FilterWrapper}>
        Sortiert nach
        <div className={styles.FilterItems}>
          {sortItems.map((item, index) => {
            return (
              <Link
                path={
                  currentSortOrder !== item.sort
                    ? getHref(pathname, query, item.sort)
                    : null
                }
                key={`sort-link-${item.name || index}`}
                className={classNames(styles.FilterItem, {
                  [styles.ActiveFilterItem]: currentSortOrder === item.sort,
                  [styles.FirstItem]: index === 0,
                  [styles.LastItem]: index === sortItems.length - 1,
                })}
                label={item.name}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SortOrder;

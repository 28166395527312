import React from 'react';
import compose from 'recompose/compose';
import { useQuery } from '@apollo/client';
import { doHandleSearchSuggestionsClickTracking } from '../../../../../../../shared/helpers/tracking';
import withDebouncedProps from '../../../../../../../shared/decorators/withDebouncedProps';
import Link from '../../../../../../../common/components/LinkLegacy';
import Icon from '../../../Icon';
import {
  GLOBAL_SEARCH_FILTER_ARTICLE,
  GLOBAL_SEARCH_FILTER_EXPLAINING_ARTICLE,
  GLOBAL_SEARCH_FILTER_NATIVE_ADVERTISING,
} from '../../../../../../../shared/constants/globalSearch';
import query from './queries';
import { AutocompleteProps } from './typings';

type AutocompletePropsInner = AutocompleteProps & {
  renderLinks: () => void;
  reset: () => void;
  data: ApolloData & {
    globalSearch: SearchableUnionGraphList;
  };
  pageSize?: number;
};

const ITEMS_COUNT = 5;

const Autocomplete = ({
  addClass,
  queryString,
  updateQueryString,
  minQueryLength,
  pageSize = ITEMS_COUNT,
}: AutocompletePropsInner) => {
  const { data } = useQuery(query, {
    skip: !queryString || queryString.length < minQueryLength,
    variables: {
      char: queryString && `${queryString}*`,
      contentTypes: [
        GLOBAL_SEARCH_FILTER_ARTICLE,
        GLOBAL_SEARCH_FILTER_NATIVE_ADVERTISING,
        GLOBAL_SEARCH_FILTER_EXPLAINING_ARTICLE,
      ],
      pageSize,
    },
  });
  const reset = () => {
    doHandleSearchSuggestionsClickTracking(queryString);
    updateQueryString('');
  };

  return (
    queryString?.length &&
    data?.globalSearch?.edges?.length && (
      <ul className={`autocomplete ${addClass}`}>
        {data.globalSearch.edges.map((item, index) => {
          if (
            !item ||
            !item.node ||
            !item.node.preferredUri ||
            !item.node.title
          ) {
            return null;
          }

          return (
            <li key={`autocomplete-list-item-${index}`}>
              <Link link={{ path: item.node.preferredUri }} onClick={reset}>
                {item.node.title}
                <Icon type="IconArrowRight" />
              </Link>
            </li>
          );
        })}
      </ul>
    )
  );
};

export default compose(withDebouncedProps())(Autocomplete);

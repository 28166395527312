import {
  UTILITY_TYPE_BOOKMARKS,
  UTILITY_TYPE_COMMENTS,
  UTILITY_TYPE_EMAIL,
  UTILITY_TYPE_FACEBOOK,
  UTILITY_TYPE_GETPOCKET,
  UTILITY_TYPE_GOOGLE_NEWS,
  UTILITY_TYPE_LINKEDIN,
  UTILITY_TYPE_MESSENGER,
  UTILITY_TYPE_PINTEREST,
  UTILITY_TYPE_PRINT,
  UTILITY_TYPE_SHARE,
  UTILITY_TYPE_TWITTER,
  UTILITY_TYPE_WHATSAPP,
  UTILITY_TYPE_XING,
} from '../../components/UtilityBar/constants';

export const CONTENT_TYPE_LABEL_NONE = null;
export const CONTENT_TYPE_LABEL_PAID = 'Paid';
export const CONTENT_TYPE_LABEL_OPEN = 'Open';
export const CONTENT_TYPE_LABEL_REDIRECTED = 'Redirected';
export const CONTENT_TYPE_LABEL_NOT_UPDATABLE = 'Not updatable';

export const UTILITYBAR_CONFIG_ARTICLE = [
  UTILITY_TYPE_SHARE,
  UTILITY_TYPE_PRINT,
  UTILITY_TYPE_BOOKMARKS,
  UTILITY_TYPE_COMMENTS,
  UTILITY_TYPE_GOOGLE_NEWS,
];

export const UTILITYBAR_CONFIG_ARTICLE_GUIDE = [
  UTILITY_TYPE_SHARE,
  UTILITY_TYPE_PRINT,
  UTILITY_TYPE_BOOKMARKS,
];

export const UTILITYBAR_OVERLAY_CONFIG_ARTICLE = [
  UTILITY_TYPE_EMAIL,
  UTILITY_TYPE_FACEBOOK,
  UTILITY_TYPE_MESSENGER,
  UTILITY_TYPE_WHATSAPP,
  UTILITY_TYPE_TWITTER,
  UTILITY_TYPE_LINKEDIN,
  UTILITY_TYPE_XING,
  UTILITY_TYPE_GETPOCKET,
  UTILITY_TYPE_PINTEREST,
];

export const PARAGRAPHS_FOR_FREE = 2;
export const PARAGRAPHS_FOR_FREE_LEGAL_ADVICE = 1;

import {
  ADVERTISING_TYPE_ADVERTORIAL,
  ADVERTISING_TYPE_BRANDREPORT,
  ADVERTISING_TYPE_EXTERNAL,
  ADVERTISING_TYPE_LONGFORM,
  ADVERTISING_TYPE_NATIVE_ARTICLE,
  ADVERTISING_TYPE_SPONSORING,
} from '../../../shared/constants/content';

export const isAdvertising = (type: string) =>
  [
    ADVERTISING_TYPE_ADVERTORIAL,
    ADVERTISING_TYPE_BRANDREPORT,
    ADVERTISING_TYPE_EXTERNAL,
    ADVERTISING_TYPE_LONGFORM,
    ADVERTISING_TYPE_NATIVE_ARTICLE,
    ADVERTISING_TYPE_SPONSORING,
  ].includes(type);

import {
  UTILITY_TYPE_BOOKMARKS,
  UTILITY_TYPE_EMAIL,
  UTILITY_TYPE_FACEBOOK,
  UTILITY_TYPE_GETPOCKET,
  UTILITY_TYPE_LINKEDIN,
  UTILITY_TYPE_MESSENGER,
  UTILITY_TYPE_PINTEREST,
  UTILITY_TYPE_PRINT,
  UTILITY_TYPE_SHARE,
  UTILITY_TYPE_TWITTER,
  UTILITY_TYPE_WHATSAPP,
  UTILITY_TYPE_XING,
} from '../../components/UtilityBar/constants';

export const EXPLAINING_TYPE_LEGAL_DICTIONARY = 'rechtslexikon';

export const CHARS_WHITELIST = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const UTILITYBAR_CONFIG_EXPLAINING_ARTICLE = [
  UTILITY_TYPE_SHARE,
  UTILITY_TYPE_PRINT,
  UTILITY_TYPE_BOOKMARKS,
];

export const UTILITYBAR_OVERLAY_CONFIG_EXPLAINING_ARTICLE = [
  UTILITY_TYPE_EMAIL,
  UTILITY_TYPE_FACEBOOK,
  UTILITY_TYPE_MESSENGER,
  UTILITY_TYPE_WHATSAPP,
  UTILITY_TYPE_TWITTER,
  UTILITY_TYPE_LINKEDIN,
  UTILITY_TYPE_XING,
  UTILITY_TYPE_GETPOCKET,
  UTILITY_TYPE_PINTEREST,
];

import React, { FormEvent, useRef, useState } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import Icon from '../Icon';
import AutoSuggestions from './components/AutoSuggestions';
import { ROUTE_LEGAL_ADVICE } from '../../constants';
import styles from './styles.legacy.css';

const SEARCH_ID = 'LegalAdviceSearchInput';

type LegalAdviceSearchProps = {
  label?: string;
  defaultValue?: string;
  addClass?: string;
  placeholder?: string;
  hasSuggestions?: boolean;
  preserveScrollProgress?: boolean;
  isLabelHidden?: boolean;
};

const LegalAdviceSearch = ({
  label = 'Rechtsratgeber durchsuchen',
  defaultValue = '',
  addClass = '',
  placeholder = 'z. B. Vorlage für eine Vollmacht',
  hasSuggestions = true,
  preserveScrollProgress = false,
  isLabelHidden = false,
}: LegalAdviceSearchProps) => {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState(
    decodeURIComponent(defaultValue),
  );
  const [hasInputFocus, setHasInputFocus] = useState(false);
  const [showAutoComplete, setShowAutoComplete] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const kmu = new URLSearchParams(location.search).get('kmu');
  const focusInput = () => inputRef.current?.focus();
  const handleClearInput = () => {
    setInputValue('');
  };

  const handleSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const encodedURI = encodeURIComponent(inputValue.toLowerCase());
    const newQueryParams = {
      ...(encodedURI && { q: encodedURI }),
      ...(kmu && { kmu }),
    };

    const queryParams = new URLSearchParams(newQueryParams).toString();

    navigate({
      pathname: `/${ROUTE_LEGAL_ADVICE}`,
      search: queryParams,
      hash: preserveScrollProgress && '#0',
    });
  };

  return (
    <form
      className={classNames(styles.Wrapper, { [addClass]: !!addClass })}
      onSubmit={handleSearch}
    >
      {!isLabelHidden && (
        <label className={styles.Label} htmlFor={SEARCH_ID}>
          {label}
        </label>
      )}

      {/* eslint-disable-next-line*/}
      <div
        className={classNames(styles.InputWrapper, {
          [styles.InputFocused]: hasInputFocus,
        })}
        onClick={focusInput}
      >
        <button className={styles.MobileSearchButton} onClick={focusInput}>
          <Icon type="IconMagnifyingGlass" addClass={styles.IconSearch} />
        </button>

        <input
          ref={inputRef}
          name="q"
          id={SEARCH_ID}
          type="search"
          aria-label={label}
          placeholder={placeholder}
          className={styles.Input}
          value={inputValue}
          onChange={(event) => {
            const { value: inputValue } = event.target;
            setInputValue(inputValue);

            if (inputValue.length > 2) {
              setShowAutoComplete(true);
            } else {
              setShowAutoComplete(false);
            }
          }}
          onFocus={() => setHasInputFocus(true)}
          onBlur={() => setHasInputFocus(false)}
        />

        {inputValue && (
          <div
            data-testid="rechtsratgeber-search-reset-button"
            onClick={handleClearInput}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                handleClearInput();
              }
            }}
            tabIndex={0}
            role="button"
            aria-label="Rechtsratgeber suche zurücksetzen"
            className={styles.IconClear}
          >
            <Icon type="IconXMark" />
          </div>
        )}

        <button className={styles.SearchButton}>
          <Icon type="IconMagnifyingGlass" addClass={styles.IconSearch} />
          Suche
        </button>
      </div>

      {hasSuggestions && showAutoComplete && (
        <AutoSuggestions
          searchQuery={inputValue}
          setInputValue={setInputValue}
          hideAutoComplete={() => setShowAutoComplete(false)}
        />
      )}
    </form>
  );
};

export default LegalAdviceSearch;

export const toolsSortOrder = [
  '2', // Mustervorlage
  '0', //Merkblatt
  '1', // Checkliste
  '9', // Fallbeispiel
  '1002', // Beobachter-Artikel
  '5', // Adressen und Links
  '3', // Gerichtsentscheid
  '10', // Scharf beobachtet
  '1001', // Grundlage
];

// @ts-ignore
import { ReactComponent as StatusPageBEO404 } from '../../../../../common/components/SVGIcon/assets/statusPage/beo_404.svg';
// @ts-ignore
import { ReactComponent as StatusPageBEO451 } from '../../../../../common/components/SVGIcon/assets/statusPage/beo_451.svg';
// @ts-ignore
import { ReactComponent as StatusPageBEO500 } from '../../../../../common/components/SVGIcon/assets/statusPage/beo_500.svg';
import { SVG_ICONS_DEFAULT_CONFIG } from '../../../../../shared/constants/svgIcons';

export const SVG_ICONS_TYPE_BEO_404 = 'svg-icons/type/beo-404';
export const SVG_ICONS_TYPE_BEO_451 = 'svg-icons/type/beo-451';
export const SVG_ICONS_TYPE_BEO_500 = 'svg-icons/type/beo-500';

export const SVG_ICONS_CONFIG = {
  ...SVG_ICONS_DEFAULT_CONFIG,
  [SVG_ICONS_TYPE_BEO_404]: StatusPageBEO404,
  [SVG_ICONS_TYPE_BEO_451]: StatusPageBEO451,
  [SVG_ICONS_TYPE_BEO_500]: StatusPageBEO500,
};

import React, { memo, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Icon from '../../../Icon';
import Autocomplete from '../Autocomplete';
import styles from './styles.legacy.css';
import { SearchFormProps } from './typings';

type SearchFormPropsInner = SearchFormProps;

const MIN_QUERY_LENGTH = 2;

const SearchForm = ({
  addClass = '',
  focusOnMount = true,
  ignoreDefaultClass = false,
  initialQuery = '',
  placeholder = 'Suchbegriff eingeben',
  searchButtonClass = '',
}: SearchFormPropsInner) => {
  const navigate = useNavigate();
  const [isInitialQueryValid, setIsInitialQueryValid] = useState(true);
  const [searchQuery, setSearchQuery] = useState(initialQuery);
  const [isAutocompleteVisible, setIsAutocompleteVisible] = useState(false);

  const inputRef = useRef(null);

  const isSearchQuery = searchQuery !== null && searchQuery !== '';
  const showAutocomplete = isAutocompleteVisible && isSearchQuery;

  useEffect(() => {
    if (focusOnMount) {
      inputRef.current && inputRef.current.focus();
    }
  }, [focusOnMount]);

  useEffect(() => {
    if (initialQuery) {
      setSearchQuery(initialQuery);
    }
  }, [initialQuery]);

  const searchChangeHandler = (event) => {
    setSearchQuery(event.target.value);
    setIsAutocompleteVisible(true);
  };

  const resetSearchQueryHandler = () => {
    setSearchQuery('');
    setIsInitialQueryValid(false);
    inputRef.current && inputRef.current.focus();
  };

  const submitHandler = (event) => {
    event.preventDefault();

    if (searchQuery && searchQuery.length >= MIN_QUERY_LENGTH) {
      // close autocomplete box
      setIsAutocompleteVisible(false);

      // navigate to search page
      const encodedQuery: string = encodeURIComponent(
        searchQuery.toLowerCase(),
      );
      navigate(`/suche/${encodedQuery}`);
    }
  };

  return (
    <form
      action="/suche"
      autoComplete="off"
      className={classNames('search-form', {
        [styles.SearchForm]: !ignoreDefaultClass,
        [addClass]: !!addClass,
      })}
      onSubmit={(event) => submitHandler(event)}
    >
      <div className={styles.SearchInputWrapper}>
        <input
          id="query"
          className={styles.SearchInput}
          placeholder={placeholder}
          type="text"
          value={searchQuery}
          onChange={searchChangeHandler}
          ref={inputRef}
          aria-label="Suchbegriff"
          name="search"
        />
        {(isSearchQuery || (isInitialQueryValid && initialQuery)) && (
          <button
            type="button"
            className={styles.ResetButton}
            onClick={resetSearchQueryHandler}
            aria-label="Suchfeld zurücksetzen"
          >
            <Icon type="IconXMark" addClass={styles.ActionIcon} />
          </button>
        )}
      </div>

      <button
        type="submit"
        className={classNames(styles.SearchButton, searchButtonClass)}
        aria-label="suchen"
      >
        <Icon type="IconMagnifyingGlass" addClass={styles.ActionIcon} />
      </button>

      <div className={styles.AutocompleteWrapper}>
        {showAutocomplete && (
          <Autocomplete
            addClass={styles.Autocomplete}
            queryString={searchQuery}
            updateQueryString={setSearchQuery}
            minQueryLength={MIN_QUERY_LENGTH}
          />
        )}
      </div>
    </form>
  );
};

export default memo<SearchFormProps>(SearchForm);

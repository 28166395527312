import React, { ReactElement } from 'react';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { getRestrictedClassName } from '../../../../../../../shared/helpers/withHelmet';
import withHelmet from '../../../../../../shared/decorators/withHelmet';
import withScrollToAnchor from '../../../../../../shared/decorators/withScrollToAnchor';
import EditButtons from '../../../../components/EditButtons';
import Paragraphs from '../../../../components/Paragraphs';
import { LANDING_PAGE_TYPE_HOME } from '../../../../../../../shared/constants/content';
import { ROOT_SCHEMA_TYPE_ORGANIZATION } from '../../../../../../../shared/constants/structuredData';
import { ENTITY_QUEUE_PARAGRAPH } from '../../../../../../../shared/constants/paragraphs';
import variables from '../../../../assets/styles/variables.legacy.css';
import grid from '../../../../../../../common/assets/styles/grid.legacy.css';
import styles from './styles.legacy.css';
import { AppNexusFactoryProps } from '../../../../../../../common/components/AppNexus/typings';
import { LandingPageProps } from '../../typings';

type LandingPagePropsInner = LandingPageProps &
  Pick<AppNexusFactoryProps, 'isAdSuppressed'> & {};

const LandingPageHome = ({
  landingPage,
  isAdSuppressed,
}: LandingPagePropsInner): ReactElement | null => {
  if (!landingPage) {
    return null;
  }

  const firstParagraphElement = landingPage.body?.[0]?.['__typename'];
  const showMarginTop = firstParagraphElement !== ENTITY_QUEUE_PARAGRAPH;

  return (
    <div
      className={classNames(`landing-page-home`, {
        [styles.Wrapper]: showMarginTop,
      })}
    >
      <EditButtons
        editContentUri={landingPage?.editContentUri}
        editRelationUri={landingPage?.editRelationUri}
        cloneContentUri={landingPage?.cloneContentUri}
      />

      <div className={getRestrictedClassName(landingPage.__typename)}>
        <Paragraphs
          pageBody={landingPage.body}
          colStyle={grid.ColXs24}
          origin={LANDING_PAGE_TYPE_HOME}
          isAdSuppressed={isAdSuppressed || landingPage?.channel?.suppressAds}
        />
      </div>
    </div>
  );
};

export default compose(
  withScrollToAnchor({ offset: variables.pageScrollOffset }),
  withHelmet({
    getNode: (mapProps: LandingPagePropsInner) => mapProps.landingPage,
    rootSchemaType: ROOT_SCHEMA_TYPE_ORGANIZATION,
  }),
)(LandingPageHome);

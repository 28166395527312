import React from 'react';

const SocialTikTok = () => (
  <svg
    width="34"
    height="32"
    viewBox="-9 -6 34 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="M14.4939 4.08132C13.6345 3.91044 12.8563 3.48888 12.2728 2.87808C11.6892 2.26728 11.3308 1.49918 11.2498 0.685762V0.333008H8.28533V11.2692C8.28349 11.7533 8.11851 12.2248 7.81356 12.6173C7.50862 13.0098 7.07904 13.3036 6.58519 13.4575C6.09134 13.6113 5.55804 13.6174 5.06024 13.475C4.56245 13.3326 4.12517 13.0488 3.80985 12.6634C3.48965 12.2716 3.31202 11.7952 3.30273 11.3032C3.29344 10.8112 3.45298 10.3293 3.7582 9.92728C4.06342 9.52527 4.49846 9.22408 5.00023 9.0674C5.502 8.91071 6.04442 8.90667 6.54883 9.05587V6.25623C5.42403 6.11169 4.27876 6.29758 3.2732 6.7879C2.26765 7.27821 1.45211 8.04843 0.940664 8.99083C0.429215 9.93322 0.247436 11.0006 0.420753 12.0438C0.594069 13.087 1.11381 14.0537 1.90728 14.8087C2.66397 15.5284 3.63421 16.0222 4.69415 16.227C5.7541 16.4318 6.85565 16.3384 7.85822 15.9587C8.86078 15.5789 9.71887 14.9301 10.3229 14.095C10.927 13.2598 11.2497 12.2763 11.2498 11.27V5.68191C12.4478 6.47807 13.8842 6.90498 15.3569 6.90255V4.16451C15.0669 4.16484 14.7776 4.13696 14.4939 4.08132Z" />
    </g>
  </svg>
);

export default SocialTikTok;

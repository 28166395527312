import React from 'react';
import Link from '../../../../../../../common/components/Link';
import styles from './styles.legacy.css';
import { LegalAdviceCategory } from '../../typings';

type LegalAdviceCategoriesProps = Partial<Pick<RouterProps, 'location'>> & {
  categories: LegalAdviceCategory[];
  label?: string;
};

const LegalAdviceCategories = ({
  categories = [],
  location,
  label = 'Unterthemen:',
}: LegalAdviceCategoriesProps) => {
  if (categories.length === 0) {
    return;
  }

  return (
    <>
      {label && <p className={styles.CategoriesLabel}>{label}</p>}
      <div className={styles.CategoriesWrapper}>
        <div className={styles.FadingWrapper}>
          {categories
            .sort((a, b) => Number(a.sortKey) - Number(b.sortKey))
            .map((category) => {
              return (
                <Link
                  key={category.id}
                  className={styles.Category}
                  path={category.path + (location?.search || '') + '#0'}
                >
                  {category.title} {!!category.count && `(${category.count})`}
                  {category.isKMU && <span className={styles.Badge}>KMU</span>}
                </Link>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default LegalAdviceCategories;

import { connect } from 'react-redux';
import compose from 'recompose/compose';
import shouldUpdate from 'recompose/shouldUpdate';
import locationStateSelector from '../selectors/locationStateSelector';
import { log } from '../helpers/utils';
// ---------------------------------------------------------------------------------- //
// HOC
// ---------------------------------------------------------------------------------- //

const withUpdatePolicy = shouldUpdate((props, nextProps): boolean => {
  const shouldUpdate: boolean =
    nextProps && nextProps.locationState && nextProps.locationState.screenReady;
  if (shouldUpdate) {
    log('with-wait-until-apollo-loaded', 'screen is ready', 'green');
  } else {
    log(
      'with-wait-until-apollo-loaded',
      'screen is not ready - abort update',
      'orange',
    );
  }
  return shouldUpdate;
});

export const mapStateToProps = (state) => ({
  locationState: locationStateSelector(state),
});

export const withStoreConnection = connect(mapStateToProps);

export default (Component) =>
  compose(withStoreConnection, withUpdatePolicy)(Component);

import navigationUserMenuFactory from '../../../../../../../../../common/components/NavigationUserMenu/factory';
import Icon from '../../../../../../components/Icon';
import MenuItem from '../MenuItem';
import { noop } from '../../../../../../../../../shared/helpers/utils';
import { links } from '../../constants';
import styles from './styles.legacy.css';

const NavigationUserMenu = navigationUserMenuFactory({
  Icon,
  MenuItem,
  links,
  closeNavigation: () => noop,
  // MenuListHeader,
  closeOnOutsideClick: true,
  styles: {
    Wrapper: styles.Wrapper,
    MenuHeader: styles.MenuHeader,
    MenuHeaderContent: styles.MenuHeaderContent,
    MenuBodyWrapper: '',
    UserInformationWrapper: styles.UserInformationWrapper,
    UserName: styles.UserName,
    UserCredentials: styles.UserCredentials,
    MenuWrapper: styles.MenuWrapper,
    Container: styles.Container,
    MenuItem: styles.MenuItem,
    CloseButton: styles.CloseButton,
    CloseIcon: styles.CloseIcon,
  },
});

export default NavigationUserMenu;

type BreadcrumbData = {
  node?: Partial<ActiveMenuTrailItem>;
};

export const mapBreadcrumbsData = (
  root: Partial<Channel>,
  prefixUrl?: string,
): BreadcrumbData => ({
  node: {
    label: root.title,
    link: `${prefixUrl ? `/${prefixUrl}` : ''}${root.preferredUri}`,
    id: root.tid || root.id,
  },
});

type ExtractParents = {
  channel: Partial<Channel>;
  key: string;
  parentsArray?: BreadcrumbData[];
  prefixUrl?: string;
};

export const extractParents = ({
  channel,
  key,
  parentsArray = [],
  prefixUrl,
}: ExtractParents): BreadcrumbData[] => {
  if (channel && typeof channel === 'object' && key in channel) {
    if (channel[key]) {
      parentsArray.push(mapBreadcrumbsData(channel[key], prefixUrl));
      extractParents({ channel: channel[key], key, parentsArray, prefixUrl });
    }
  }
  return parentsArray;
};

import React from 'react';
import Link from '../../../../../Link';
import { MinistageAuthorFactoryOptions, MinistageAuthorProps } from './typings';

const MinistageAuthorFactory = ({
  styles,
  TeaserGrid,
  teaserGridLayout,
  Icon,
}: MinistageAuthorFactoryOptions) => {
  const MinistageAuthor = ({ ministageAuthor }: MinistageAuthorProps) => {
    const { authors, title, link } = ministageAuthor;
    const items = authors?.edges.map((author) => ({
      ...author.node,
      author: author.node,
    }));

    return (
      <div className={styles.Wrapper}>
        <div className={styles.TitleWrapper}>
          <h2 className={styles.Title}>
            {link && link.path ? (
              <Link path={link.path} className={styles.Link}>
                <>
                  {title}
                  {Icon || null}
                </>
              </Link>
            ) : (
              <>{title}</>
            )}
          </h2>
        </div>
        <TeaserGrid layout={teaserGridLayout} items={items} />
      </div>
    );
  };
  return MinistageAuthor;
};

export default MinistageAuthorFactory;

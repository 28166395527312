import React, { ReactElement } from 'react';
import mod from 'react-swipeable-views-core/lib/mod';
import classNames from 'classnames';
import styles from './styles.legacy.css';
import { DotsIndicatorProps } from './typings';

type DotsIndicatorPropsInner = DotsIndicatorProps;

const DotsIndicator = ({
  slideCount,
  activeIndex,
  dotClickHandler,
}: DotsIndicatorPropsInner): ReactElement => (
  <div className={styles.Wrapper}>
    {[...Array(slideCount)].map(
      (_, i: number): ReactElement => (
        <a
          href="#carouselToggle"
          key={`carousel-toggle-${i + activeIndex}`}
          onClick={(event) => {
            event.preventDefault();
            // virtualized slider works with 6 items by default
            // if you change default values then you need to change them here too
            dotClickHandler(mod(i, 6));
          }}
          className={classNames(styles.SwipeIndicator, {
            [styles.Active]:
              mod(i, slideCount) === mod(activeIndex, slideCount),
          })}
        >
          {''}
        </a>
      ),
    )}
  </div>
);

export default DotsIndicator;

import React from 'react';
import TeaserGrid from '../../../../../TeaserGrid';
import SVGIcon from '../../../../../SVGIcon';
import ministageAuthorFactory from '../../../../../../../../../common/components/Paragraphs/components/MinistageParagraph/components/MinistageAuthor/factory';
import { GRID_LAYOUT_TEASER_AUTHOR } from '../../../../../TeaserGrid/gridConfigs/constants';
import { SVG_ICONS_TYPE_CHEVRON_RIGHT } from '../../../../../../../../../shared/constants/svgIcons';
import styles from './styles.legacy.css';

const MinistageAuthor = ministageAuthorFactory({
  styles: {
    Wrapper: styles.Wrapper,
    TitleWrapper: styles.TitleWrapper,
    Title: styles.Title,
    Link: styles.Link,
  },
  TeaserGrid,
  teaserGridLayout: GRID_LAYOUT_TEASER_AUTHOR,
  Icon: <SVGIcon className={styles.Icon} type={SVG_ICONS_TYPE_CHEVRON_RIGHT} />,
});

export default MinistageAuthor;
